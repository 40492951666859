export const vouchers_vou = <svg width="485" height="126" viewBox="0 0 485 126" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M155.879 88.5977C155.879 109.675 143.49 125.031 114.937 125.031H0V-0.000549316H110.625C139.178 -0.000549316 147.981 14.4661 147.981 32.8656C147.981 41.6207 144.384 50.1979 138.095 57.1545C148.875 63.7653 155.879 74.1311 155.879 88.5977ZM32.2885 49.1208H99.091C107.714 49.1208 115.613 49.1208 115.613 36.0771C115.613 23.231 107.665 23.231 98.9121 23.231H32.2885V49.1208ZM123.511 86.987C123.511 73.7753 115.563 73.7753 106.989 73.7753H32.2885V100.209H106.989C115.652 100.209 123.551 100.209 123.551 86.987H123.511Z" fill="white" />
    <path d="M296.13 98.42H220.893L207.421 125.031H171.864L236.69 -0.000549316H280.334L345.338 125.031H309.572L296.13 98.42ZM284.099 74.4868L258.606 23.7548L232.974 74.4868H284.099Z" fill="white" />
    <path d="M484.904 -0.000549316L424.817 125.999H390.006L404.063 96.6314H383.22L332.919 -0.000549316H367.9L410.869 82.4217L450.082 -0.000549316H484.904Z" fill="white" />
</svg>

export const vouchers_chers = <svg width="692" height="126" viewBox="0 0 692 126" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M562.349 -0.000549316V93.9535L648.187 -0.000549316H692V125.031H659.682V31.2549L574.013 125.031H530.021V-0.000549316H562.349Z" fill="white" />
    <path d="M219.853 25.5433V50.9093H320.424V74.1311H219.853V99.4971H320.424V125.031H187.524V-0.000549316H320.424V25.5433H219.853Z" fill="white" />
    <path d="M117.205 -0.000549316V59.4371C108.075 69.8622 86.4066 80.5837 65.5432 80.5837C42.4147 80.5837 33.4037 71.2753 33.4037 47.3816V-0.000549316H1.01585L0.658203 58.9628C0.966186 91.7993 19.7035 108.45 56.2938 108.45C85.1051 108.45 105.134 98.0939 117.155 89.0226V125.031H149.324V-0.000549316H117.205Z" fill="white" />
    <path d="M452.727 -0.000549316H353.964V125.031H386.282V91.6313H452.727C481.28 91.6313 500.862 73.7753 500.862 45.9093C500.862 18.0432 481.28 -0.000549316 452.727 -0.000549316ZM463.795 66.9669L447.333 58.0735L443.865 76.3248L431.437 62.3324L422.972 78.973L414.458 62.3324L402.039 76.3248L398.562 58.034L382.1 66.9274L387.475 49.4073L369.79 44.7432L387.475 40.0791L382.1 22.5591L398.562 31.4525L402.029 13.1716L414.458 27.2331L422.932 10.5826L431.397 27.2232L443.816 13.2408L447.293 31.5217L463.755 22.6283L458.39 40.1483L476.064 44.8124L458.38 49.4765L463.795 66.9669Z" fill="white" />
</svg>

export const twoPointO = <svg width="322" height="124" viewBox="0 0 322 124" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M44.1101 95.1428H133.018V120.305H2V105.172C2 56.6714 104.602 67.498 104.602 40.9997C104.602 31.3395 98.4245 27.1724 72.5267 27.1724C46.6289 27.1724 34.7545 31.0006 34.7545 49.5035H4.67874C4.67874 10.6234 32.0858 2 70.8475 2C111.119 2 134.687 9.66636 134.687 38.6669C134.687 83.8377 51.4667 72.0041 44.1101 95.1428Z" stroke="#F5EB5D" strokeWidth="4" strokeMiterlimit="10" />
    <path d="M175.958 98.004V120.335H147.381V98.004H175.958Z" stroke="#F5EB5D" strokeWidth="4" strokeMiterlimit="10" />
    <path d="M182.975 61.8356C182.975 23.1648 205.034 2.02002 251.492 2.02002C297.78 2.02002 320 23.3542 320 61.8356C320 100.506 297.78 122 251.492 122C205.034 122 182.975 100.666 182.975 61.8356ZM289.924 61.8356C289.924 40.1724 277.93 27.1725 251.492 27.1725C225.055 27.1725 213.06 40.0029 213.06 61.8356C213.06 83.8377 225.055 96.8376 251.492 96.8376C277.93 96.8376 289.924 83.6683 289.924 61.8356Z" stroke="#F5EB5D" strokeWidth="4" strokeMiterlimit="10" />
</svg>

export const arrow_down = <svg width="34" height="19" viewBox="0 0 34 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L17 17L33 1" stroke="#161616" strokeWidth="2" />
</svg>

