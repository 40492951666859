
//npm imports
import styled, { keyframes } from 'styled-components/macro';

const spinAnimation = keyframes`
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
`

//styled-components
const StyledAnimationSpinner = styled.div`
width: max-content;
    position: relative;
    .rules-home{
        display: ${props => props.animationType === "rules-home" ? "block" : "none"};
    }
    .academy-list{
        display: ${props => props.animationType === "academy-list" ? "block" : "none"};
    }
    .rules{
        display: ${props => props.animationType === "rules" ? "block" : "none"};
    }
    .spinner{
        animation: ${spinAnimation} 4s linear forwards infinite;
    }
    .center-item{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`

//main component
const AnimationSpinner = ({ animationType, className }) => {

    return (
        // animationType is rules or academy-list
        <StyledAnimationSpinner animationType={animationType} className={className}>
            <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" className="rules spinner">
                <g clipPath="url(#clip0_606_246)">
                    <path d="M12.4731 93.8019L20.9584 86.7715L22.2357 88.3138L15.0728 94.2498L18.0752 97.875L25.2382 91.9416L26.5155 93.4839L18.0302 100.514L12.4731 93.8019Z" fill="#2D2E34" />
                    <path d="M6.91082 84.0338C6.26157 82.571 6.03102 81.3308 6.21917 80.3132C6.40732 79.2982 7.06717 78.5377 8.20137 78.0342C8.79232 77.7718 9.34617 77.6526 9.85763 77.6738C10.3691 77.695 10.8487 77.846 11.2939 78.1243C11.7365 78.4025 12.1472 78.8 12.5262 79.3168C12.9051 79.8335 13.2576 80.4589 13.5862 81.1956L13.9916 82.1125L17.5373 80.5411L18.3482 82.3722L8.42132 86.7739C8.13512 86.3897 7.85952 85.9471 7.59717 85.4436C7.33217 84.9401 7.10162 84.471 6.91082 84.0338ZM8.43457 83.2017C8.64127 83.6681 8.82412 84.0444 8.98842 84.3306L12.4175 82.8095L12.0253 81.9217C11.6358 81.0393 11.2091 80.4165 10.7533 80.0508C10.2949 79.6851 9.72247 79.656 9.03612 79.9607C8.70752 80.1065 8.45842 80.292 8.29147 80.5172C8.12452 80.7425 8.02647 80.9942 7.99997 81.2725C7.97347 81.5507 7.99997 81.8555 8.08212 82.1841C8.16427 82.5127 8.28087 82.8519 8.43457 83.2017Z" fill="#2D2E34" />
                    <path d="M12.9343 64.2626C12.5288 64.4772 12.1287 64.6972 11.7365 64.9224C11.3416 65.1477 10.9388 65.3676 10.5201 65.5849L11.2515 70.18C11.7153 70.2569 12.1711 70.339 12.6189 70.4238C13.0668 70.5086 13.512 70.5908 13.9545 70.6703L14.2831 72.7267C13.0562 72.5041 11.922 72.2868 10.8805 72.0774C9.83909 71.8681 8.85064 71.6587 7.91518 71.4467C6.97973 71.2374 6.08668 71.0227 5.23603 70.8028C4.38538 70.5855 3.54268 70.3523 2.71058 70.1111L2.41113 68.2217C3.12663 67.7314 3.85538 67.2518 4.59738 66.7801C5.33673 66.3084 6.12113 65.8261 6.94263 65.3358C7.76679 64.8456 8.64128 64.3341 9.56613 63.8068C10.491 63.2794 11.5006 62.7176 12.5977 62.124L12.9343 64.2626ZM4.66893 68.8709C5.26253 69.0246 5.98068 69.1916 6.82603 69.3718C7.67138 69.5546 8.58563 69.7375 9.57144 69.9256L9.00169 66.3455C8.12454 66.8278 7.30568 67.2915 6.55043 67.7341C5.79253 68.1766 5.16448 68.5556 4.66893 68.8709Z" fill="#2D2E34" />
                    <path d="M13.2524 54.9769C13.1835 55.5546 13.0934 56.1376 12.9847 56.7232C12.8761 57.3089 12.7383 57.815 12.5687 58.2417L1.9448 56.9617C1.93155 56.7259 1.92095 56.4741 1.91565 56.2065C1.91035 55.9388 1.913 55.6659 1.92625 55.3903C1.9395 55.1147 1.9554 54.8417 1.9766 54.5714C1.9978 54.3011 2.0243 54.0467 2.05345 53.8029C2.13295 53.1404 2.2575 52.5309 2.4271 51.9744C2.5967 51.4179 2.8193 50.9462 3.10285 50.5593C3.38375 50.1724 3.72825 49.8836 4.13635 49.6981C4.54445 49.5126 5.02675 49.4543 5.58325 49.5205C6.1 49.5841 6.52665 49.759 6.8632 50.0505C7.2024 50.342 7.45415 50.721 7.62375 51.19C7.92585 50.554 8.3101 50.1035 8.77915 49.8412C9.2482 49.5762 9.8153 49.4861 10.4778 49.5656C11.6041 49.7007 12.3991 50.2122 12.8628 51.1026C13.3266 51.9903 13.4564 53.2835 13.2524 54.9769ZM6.4763 55.5228L6.6459 54.1183C6.736 53.3604 6.70155 52.7535 6.53725 52.2951C6.37295 51.8366 6.01255 51.5743 5.45605 51.508C4.9287 51.4444 4.52855 51.6246 4.25295 52.0433C3.97735 52.4647 3.79715 53.0318 3.71235 53.7473C3.67525 54.052 3.64875 54.325 3.6302 54.5635C3.61165 54.802 3.6037 55.006 3.6037 55.1756L6.4763 55.5228ZM8.0398 55.7109L11.3709 56.1137C11.4186 55.8938 11.4636 55.6606 11.5034 55.4088C11.5458 55.1571 11.5776 54.9265 11.6041 54.7172C11.6544 54.3064 11.6729 53.9222 11.6623 53.5644C11.6518 53.204 11.5961 52.8887 11.4981 52.6157C11.3974 52.3428 11.2463 52.1149 11.0422 51.9373C10.8408 51.7571 10.5652 51.6485 10.2181 51.6061C9.598 51.5319 9.13955 51.7068 8.84275 52.1281C8.54595 52.5521 8.34985 53.1643 8.25445 53.9619L8.0398 55.7109Z" fill="#2D2E34" />
                    <path d="M14.8898 45.5746L4.76416 41.2312L5.54856 39.4054L12.2716 42.2886C11.8874 41.7321 11.4819 41.112 11.0553 40.4256C10.6286 39.7419 10.2099 39.0423 9.79916 38.3295C9.38841 37.6166 9.00151 36.917 8.63316 36.228C8.26481 35.539 7.96006 34.9136 7.71891 34.3465L8.36551 32.8413L18.4912 37.1847L17.7147 38.9973L10.467 35.8888C10.7797 36.5063 11.1427 37.1714 11.5561 37.8869C11.9695 38.5998 12.4068 39.3153 12.8679 40.0334C13.329 40.7489 13.7901 41.4485 14.2512 42.1322C14.7149 42.8159 15.1522 43.4413 15.5656 44.0111L14.8898 45.5746Z" fill="#2D2E34" />
                    <path d="M16.7953 23.6619C17.3226 24.1548 17.8579 24.6583 18.3985 25.1751C18.9391 25.6918 19.4373 26.2192 19.8931 26.7545C20.3489 27.2898 20.7411 27.8383 21.0724 28.3975C21.4036 28.9566 21.6236 29.529 21.7322 30.1094C21.8409 30.6897 21.8223 31.2833 21.6686 31.8849C21.5176 32.4864 21.189 33.1012 20.6802 33.7266L19.2041 32.762C19.4188 32.444 19.5804 32.1419 19.6838 31.8557C19.7898 31.5695 19.8428 31.2886 19.8481 31.013C19.8534 30.7374 19.8083 30.4565 19.7129 30.165C19.6175 29.8762 19.4771 29.5741 19.2863 29.2587C18.8437 28.5035 18.1812 27.6873 17.2988 26.8101C16.4163 25.933 15.3961 24.9975 14.2354 24.0064L18.7033 18.77L27.0852 25.9224L25.7735 27.4594L18.698 21.4227L16.7953 23.6619Z" fill="#2D2E34" />
                    <path d="M37.0761 18.6453C36.713 18.367 36.3473 18.094 35.979 17.8264C35.6106 17.5614 35.2423 17.2831 34.8739 16.9943L30.9943 19.5675C31.1162 20.0206 31.2302 20.4685 31.3415 20.911C31.4501 21.3536 31.5588 21.7935 31.6701 22.2281L29.9343 23.3808C29.6296 22.1724 29.354 21.0488 29.1128 20.0153C28.8717 18.9818 28.6517 17.9934 28.4556 17.0553C28.2595 16.1171 28.0846 15.2161 27.9309 14.3496C27.7772 13.4857 27.6368 12.6244 27.5122 11.7658L29.1049 10.7085C29.8469 11.1563 30.5862 11.6201 31.3229 12.0997C32.0596 12.5767 32.8228 13.0908 33.6099 13.6367C34.3969 14.1826 35.2237 14.7656 36.0876 15.3884C36.9515 16.0111 37.8817 16.7001 38.8754 17.4501L37.0761 18.6453ZM29.4547 13.0325C29.5607 13.6367 29.7091 14.3602 29.8919 15.2029C30.0774 16.0482 30.2894 16.9572 30.5279 17.9298L33.5489 15.9263C32.7433 15.3274 31.9854 14.7762 31.2673 14.2701C30.5518 13.7692 29.9476 13.3558 29.4547 13.0325Z" fill="#2D2E34" />
                </g>
                <defs>
                    <clipPath id="clip0_606_246">
                        <rect width="120" height="120" fill="white" />
                    </clipPath>
                </defs>
            </svg>


            <svg width="141" height="141" viewBox="0 0 141 141" fill="none" xmlns="http://www.w3.org/2000/svg" className="academy-list spinner">
                <path d="M19.4311 112.184C20.105 111.68 20.7945 111.17 21.4994 110.654C22.2043 110.141 22.9123 109.674 23.6203 109.263C24.3283 108.848 25.0393 108.511 25.7504 108.245C26.4615 107.983 27.1633 107.847 27.8528 107.84C28.5422 107.834 29.2193 107.98 29.8809 108.276C30.5425 108.573 31.1794 109.077 31.7946 109.788L30.3817 111.285C30.0602 110.972 29.748 110.725 29.4388 110.546C29.1327 110.366 28.8205 110.246 28.5051 110.184C28.1898 110.122 27.8559 110.116 27.5065 110.165C27.1571 110.215 26.7769 110.317 26.3749 110.468C25.4165 110.821 24.3437 111.414 23.1565 112.246C21.9693 113.078 20.6863 114.058 19.3074 115.189L14.2092 108.981L24.1459 100.822L25.6422 102.643L17.2514 109.532L19.4311 112.184Z" fill="black" />
                <path d="M21.1129 95.7582L9.22237 100.64L8.34124 98.4944L16.2343 95.2543C15.4583 95.1183 14.6081 94.9482 13.6898 94.7442C12.7716 94.5401 11.8472 94.3175 10.9197 94.0764C9.99219 93.8352 9.09252 93.5879 8.21758 93.3313C7.34263 93.0747 6.57281 92.8181 5.90501 92.5553L5.17847 90.7868L17.069 85.9051L17.944 88.0322L9.4326 91.5257C10.2024 91.77 11.0526 92.0142 11.9863 92.2523C12.92 92.4904 13.8722 92.7099 14.8461 92.9139C15.82 93.118 16.7784 93.3035 17.7245 93.4766C18.6705 93.6497 19.5516 93.7981 20.3617 93.9187L21.1129 95.7582Z" fill="black" />
                <path d="M15.4335 74.4103C15.5356 75.3687 15.4768 76.2467 15.2604 77.0382C15.044 77.8328 14.6792 78.5222 14.1629 79.1127C13.6466 79.7001 12.9911 80.1793 12.1966 80.5503C11.402 80.9213 10.4714 81.1594 9.39861 81.2738C8.3289 81.3851 7.3643 81.3233 6.5079 81.0821C5.65151 80.841 4.90333 80.47 4.27263 79.9629C3.64193 79.4559 3.13799 78.8376 2.76389 78.1079C2.3898 77.3752 2.15484 76.5745 2.06518 75.6995C2.00953 75.1708 2.00025 74.6854 2.03426 74.2464C2.06827 73.8074 2.12083 73.424 2.18266 73.0932C2.24759 72.7624 2.32488 72.4873 2.41454 72.2678C2.50419 72.0482 2.56912 71.8906 2.60931 71.8009L4.5818 72.2832C4.42721 72.5986 4.29736 73.0376 4.18915 73.6034C4.08095 74.1691 4.0593 74.7689 4.12732 75.4089C4.18606 75.9623 4.33446 76.4693 4.57561 76.93C4.81676 77.3907 5.14448 77.774 5.56495 78.0863C5.98232 78.3954 6.49245 78.6211 7.08914 78.7572C7.68892 78.8932 8.36909 78.921 9.12964 78.8437C9.80671 78.7726 10.4189 78.6304 10.9692 78.4171C11.5195 78.2038 11.9832 77.9162 12.3604 77.5545C12.7407 77.1928 13.0159 76.7538 13.1921 76.2375C13.3683 75.7211 13.4209 75.1183 13.3498 74.4319C13.2632 73.6095 13.1086 72.9479 12.8891 72.4471C12.6696 71.9462 12.4717 71.5659 12.2924 71.297L14.1258 70.4715C14.2402 70.6075 14.3607 70.7992 14.4844 71.0404C14.6081 71.2815 14.7348 71.5721 14.8647 71.906C14.9914 72.2399 15.1089 72.6202 15.2079 73.0438C15.3068 73.4611 15.381 73.9187 15.4335 74.4103Z" fill="black" />
                <path d="M3.79964 54.2773L5.79686 54.5958L5.17543 58.4975L15.8726 60.201L15.5016 62.529L4.80443 60.8255L4.18301 64.7272L2.18579 64.4087L3.79964 54.2773Z" fill="black" />
                <path d="M20.9152 44.8972C20.3803 44.857 19.8485 44.8261 19.3229 44.8014C18.7943 44.7797 18.2563 44.7457 17.7122 44.6993L15.5789 49.6924C15.9901 50.0572 16.389 50.4189 16.7785 50.7806C17.1681 51.1424 17.5576 51.501 17.9441 51.8535L16.9887 54.0887C15.9159 53.1056 14.9297 52.1873 14.03 51.334C13.1303 50.4807 12.2863 49.6584 11.4918 48.87C10.7003 48.0816 9.9521 47.3149 9.2472 46.5698C8.5423 45.8247 7.85595 45.0734 7.18506 44.316L8.06 42.2631C9.07098 42.226 10.0912 42.2013 11.1146 42.1982C12.141 42.192 13.2107 42.2013 14.3299 42.2291C15.4491 42.2569 16.627 42.2971 17.8699 42.3497C19.1127 42.4022 20.4576 42.4764 21.9107 42.5723L20.9152 44.8972ZM9.89027 44.3129C10.3818 44.8323 10.9878 45.4444 11.7082 46.1493C12.4285 46.8542 13.2169 47.6055 14.0733 48.4001L15.7366 44.5107C14.568 44.4427 13.4735 44.3933 12.4533 44.3593C11.4299 44.3252 10.5766 44.3098 9.89027 44.3129Z" fill="black" />
                <path d="M26.2915 18.0213L28.0197 16.4446L36.6826 25.9422L34.9543 27.5158L31.1176 23.3081L26.9902 27.0768L30.827 31.2846L29.0987 32.8613L20.439 23.3606L22.1672 21.787L25.6299 25.5835L29.7542 21.821L26.2915 18.0213Z" fill="black" />
                <path d="M49.3058 18.8993C48.9286 18.5191 48.5453 18.1481 48.1619 17.7863C47.7785 17.4246 47.3952 17.0474 47.0118 16.6548L42.1332 19.0354C42.2043 19.5795 42.2692 20.1144 42.3279 20.643C42.3867 21.1717 42.4454 21.6973 42.5072 22.2167L40.3214 23.2833C40.1514 21.8395 40.0061 20.4977 39.8855 19.2641C39.7649 18.0306 39.6629 16.8557 39.5794 15.7396C39.496 14.6236 39.431 13.5569 39.3847 12.5305C39.3383 11.5041 39.3105 10.49 39.2981 9.47593L41.3046 8.49896C42.0961 9.13275 42.8782 9.782 43.6573 10.4467C44.4364 11.1114 45.2403 11.8225 46.0688 12.5738C46.8974 13.3251 47.7631 14.1289 48.6689 14.9791C49.5748 15.8293 50.5425 16.7692 51.5782 17.7894L49.3058 18.8993ZM41.3479 11.2444C41.3788 11.9585 41.4375 12.818 41.521 13.8228C41.6045 14.8276 41.7127 15.9128 41.8394 17.0752L45.6391 15.2202C44.8013 14.404 44.0067 13.6497 43.2554 12.9571C42.5042 12.2615 41.8704 11.6927 41.3479 11.2444Z" fill="black" />
                <path d="M71.937 14.908C71.7484 14.4072 71.5506 13.9125 71.3496 13.424C71.1486 12.9355 70.9508 12.4347 70.7622 11.9215L65.3363 12.0946C65.1786 12.6202 65.0179 13.1365 64.854 13.6404C64.6901 14.1475 64.5294 14.6514 64.3717 15.1492L61.9417 15.2265C62.3807 13.8383 62.7949 12.5583 63.1907 11.3804C63.5864 10.2056 63.9729 9.09259 64.3531 8.03833C64.7334 6.98716 65.1137 5.98546 65.4909 5.03323C65.8681 4.08099 66.2576 3.14113 66.6626 2.21363L68.8917 2.14252C69.3524 3.04528 69.8038 3.95733 70.2428 4.88483C70.6818 5.81233 71.1239 6.7862 71.5691 7.81264C72.0174 8.83598 72.4781 9.92425 72.9542 11.0713C73.4303 12.2183 73.9311 13.4704 74.4567 14.8276L71.937 14.908ZM67.8158 4.66532C67.553 5.33003 67.2531 6.13695 66.9192 7.08919C66.5853 8.04142 66.236 9.07095 65.8773 10.1839L70.1037 10.0479C69.6739 8.95965 69.2565 7.94867 68.8546 7.0088C68.4527 6.06584 68.1064 5.28674 67.8158 4.66532Z" fill="black" />
                <path d="M86.3657 17.3999C86.174 16.8434 85.9391 16.2498 85.6608 15.6191C85.3857 14.9884 85.0827 14.3732 84.755 13.7703C84.4272 13.1643 84.0779 12.5831 83.71 12.0173C83.339 11.4546 82.9618 10.9507 82.5753 10.5117L81.1841 16.1107L78.9148 15.548L82.0157 3.07312L84.285 3.6358L83.0051 8.78343C83.4997 8.48663 84.0191 8.1651 84.5633 7.82192C85.1074 7.47875 85.6484 7.12939 86.1895 6.77385C86.7305 6.4183 87.2468 6.07204 87.7384 5.73505C88.23 5.39805 88.6752 5.09507 89.074 4.823L91.7916 5.50008C91.2815 5.85871 90.7435 6.22662 90.1777 6.60999C89.612 6.99335 89.0307 7.37363 88.4402 7.757C87.8466 8.13727 87.253 8.51755 86.6563 8.89164C86.0596 9.26573 85.4784 9.61818 84.9126 9.94899C85.3207 10.4591 85.7226 11.0342 86.1184 11.6741C86.5141 12.3141 86.8913 12.9881 87.2499 13.6961C87.6086 14.4041 87.9425 15.1306 88.2485 15.8757C88.5546 16.6208 88.8205 17.3535 89.0493 18.0739L86.3657 17.3999Z" fill="black" />
                <path d="M101.048 24.4365C101.153 23.911 101.252 23.3885 101.342 22.866C101.431 22.3466 101.53 21.8179 101.645 21.28L96.9577 18.5376C96.5465 18.8994 96.1353 19.2487 95.7272 19.5919C95.3191 19.932 94.9141 20.2752 94.5153 20.6121L92.416 19.3848C93.5259 18.4418 94.5585 17.5792 95.52 16.7939C96.4816 16.0087 97.3998 15.2728 98.284 14.5834C99.1651 13.897 100.018 13.2509 100.847 12.6449C101.672 12.039 102.504 11.4515 103.339 10.8796L105.265 12.0049C105.175 13.0128 105.07 14.0269 104.95 15.0441C104.829 16.0612 104.684 17.1217 104.517 18.2285C104.35 19.3353 104.161 20.5008 103.954 21.7252C103.747 22.9495 103.503 24.2758 103.228 25.7041L101.048 24.4365ZM103.011 13.5693C102.433 13.9929 101.753 14.5154 100.961 15.143C100.173 15.7706 99.329 16.4569 98.4324 17.2082L102.084 19.3446C102.297 18.1945 102.486 17.1155 102.646 16.1045C102.804 15.0966 102.925 14.2526 103.011 13.5693Z" fill="black" />
                <path d="M107.531 26.5605L108.604 27.7416C109.547 27.6395 110.431 27.4138 111.263 27.0676C112.091 26.7213 112.892 26.2977 113.662 25.7907C114.432 25.2837 115.183 24.7148 115.916 24.081C116.649 23.4472 117.4 22.7856 118.157 22.0931L123.459 27.9301L115.412 35.2389L116.698 36.6517L112.688 40.2937L111.179 38.6335L113.721 36.3271L107.571 29.5564L105.033 31.8627L103.524 30.2025L107.531 26.5605ZM118.185 25.1044C117.61 25.5774 117.02 26.0535 116.407 26.542C115.798 27.0274 115.168 27.4819 114.518 27.8961C113.869 28.3104 113.195 28.6752 112.503 28.9875C111.81 29.2998 111.087 29.5224 110.338 29.6522L113.869 33.5384L120.433 27.5777L118.185 25.1044Z" fill="black" />
                <path d="M117.573 42.1363L129.089 36.426L132.756 43.8213L130.978 44.7024L128.351 39.4002L125.509 40.81L127.85 45.531L126.106 46.3967L123.766 41.6757L120.393 43.3483L123.218 49.0493L121.441 49.9304L117.573 42.1363Z" fill="black" />
                <path d="M136.373 55.3285C135.953 55.6716 135.433 56.0643 134.815 56.5033C134.197 56.9423 133.547 57.3906 132.864 57.8544C132.181 58.315 131.485 58.7757 130.777 59.2363C130.069 59.697 129.414 60.1175 128.811 60.5039C129.504 60.6801 130.258 60.8718 131.074 61.0759C131.89 61.283 132.694 61.4963 133.489 61.7189C134.283 61.9415 135.041 62.1641 135.767 62.3836C136.491 62.6032 137.109 62.8103 137.616 63.0051L137.953 65.0827C137.044 65.3455 136.073 65.6114 135.047 65.8772C134.021 66.1431 132.966 66.409 131.887 66.6656C130.808 66.9222 129.72 67.1757 128.629 67.4231C127.537 67.6704 126.468 67.9054 125.426 68.1249L125.058 65.8556C126.526 65.5681 128.038 65.262 129.59 64.9405C131.142 64.6189 132.639 64.2727 134.082 63.8986C133.779 63.8089 133.418 63.7069 132.997 63.5863C132.577 63.4657 132.122 63.339 131.631 63.206C131.139 63.0731 130.632 62.9371 130.11 62.8041C129.587 62.6712 129.08 62.5382 128.588 62.4115C128.097 62.2847 127.639 62.1672 127.216 62.059C126.792 61.9508 126.43 61.8612 126.128 61.7839L125.849 60.0618C126.109 59.8949 126.427 59.6908 126.805 59.4558C127.179 59.2209 127.581 58.9643 128.004 58.6891C128.428 58.4139 128.87 58.1295 129.321 57.8358C129.776 57.5421 130.212 57.2546 130.629 56.9763C131.046 56.695 131.439 56.4322 131.807 56.1849C132.175 55.9375 132.481 55.7242 132.728 55.548C131.241 55.65 129.711 55.7922 128.137 55.9746C126.563 56.1601 125.033 56.3456 123.549 56.5342L123.181 54.2649C124.239 54.1444 125.336 54.0269 126.468 53.9156C127.602 53.8012 128.728 53.6961 129.847 53.5971C130.966 53.4982 132.051 53.4147 133.105 53.3467C134.16 53.2787 135.134 53.2292 136.03 53.1983L136.373 55.3285Z" fill="black" />
                <path d="M125.135 73.6033L137.823 75.6778L137.449 77.9657L129.028 76.5899C129.618 77.1124 130.252 77.7029 130.929 78.3583C131.606 79.0137 132.274 79.6877 132.939 80.3772C133.603 81.0697 134.24 81.753 134.849 82.43C135.458 83.1071 135.981 83.7316 136.414 84.3005L136.104 86.1864L123.416 84.1119L123.787 81.8426L132.867 83.3266C132.342 82.7145 131.742 82.0621 131.074 81.3696C130.403 80.6771 129.708 79.9907 128.987 79.3075C128.267 78.6242 127.547 77.9626 126.833 77.3195C126.115 76.6764 125.444 76.089 124.817 75.5604L125.135 73.6033Z" fill="black" />
                <path d="M121.611 89.7388L133.124 95.4553L132.091 97.5329L124.449 93.7394C124.86 94.4134 125.293 95.1616 125.744 95.987C126.196 96.8125 126.638 97.6534 127.071 98.5098C127.5 99.3662 127.908 100.207 128.292 101.033C128.675 101.858 128.99 102.609 129.235 103.283L128.384 104.993L116.871 99.2766L117.894 97.2175L126.14 101.305C125.818 100.566 125.438 99.765 125.002 98.9056C124.566 98.0461 124.102 97.1866 123.614 96.3209C123.126 95.4583 122.634 94.6112 122.139 93.7858C121.645 92.9603 121.175 92.2028 120.733 91.5134L121.611 89.7388Z" fill="black" />
            </svg>

            <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg" className="rules-home spinner">
                <g clipPath="url(#clip0_349_241)">
                    <path d="M14.5525 109.436L24.452 101.234L25.9422 103.033L17.5854 109.958L21.0883 114.188L29.4451 107.265L30.9352 109.065L21.0357 117.267L14.5525 109.436Z" fill="#F9F9F9" />
                    <path d="M8.06303 98.0397C7.30558 96.3331 7.0366 94.8863 7.25611 93.6991C7.47562 92.5149 8.24544 91.6276 9.56868 91.0402C10.2581 90.7341 10.9043 90.595 11.501 90.6197C12.0977 90.6445 12.6573 90.8207 13.1767 91.1453C13.693 91.47 14.1722 91.9337 14.6143 92.5366C15.0564 93.1395 15.4676 93.8691 15.8509 94.7286L16.324 95.7983L20.4606 93.9649L21.4067 96.1013L9.82529 101.237C9.49139 100.788 9.16985 100.272 8.86378 99.6845C8.55461 99.0971 8.28563 98.5499 8.06303 98.0397ZM9.84074 97.069C10.0819 97.6131 10.2952 98.0521 10.4869 98.386L14.4875 96.6114L14.03 95.5757C13.5755 94.5462 13.0777 93.8196 12.546 93.393C12.0111 92.9663 11.3433 92.9323 10.5426 93.2879C10.1592 93.4579 9.86857 93.6743 9.67379 93.9371C9.47902 94.1999 9.36463 94.4936 9.33371 94.8182C9.30279 95.1429 9.33371 95.4984 9.42955 95.8818C9.52539 96.2651 9.66143 96.6609 9.84074 97.069Z" fill="#F9F9F9" />
                    <path d="M15.0902 74.973C14.6172 75.2234 14.1504 75.48 13.6928 75.7428C13.2322 76.0056 12.7622 76.2622 12.2737 76.5157L13.127 81.8767C13.6681 81.9664 14.1998 82.0622 14.7223 82.1611C15.2448 82.2601 15.7642 82.3559 16.2805 82.4487L16.6639 84.8478C15.2325 84.5881 13.9092 84.3346 12.6942 84.0903C11.4792 83.8461 10.326 83.6018 9.23463 83.3545C8.14327 83.1103 7.10138 82.8598 6.10895 82.6032C5.11652 82.3497 4.13337 82.0777 3.16259 81.7963L2.81323 79.592C3.64798 79.02 4.49819 78.4604 5.36386 77.9101C6.22643 77.3598 7.14157 76.7971 8.09998 76.2251C9.06149 75.6532 10.0817 75.0565 11.1607 74.4412C12.2397 73.826 13.4177 73.1706 14.6976 72.478L15.0902 74.973ZM5.44733 80.3494C6.13987 80.5287 6.97771 80.7235 7.96395 80.9337C8.95019 81.1471 10.0168 81.3604 11.1669 81.5799L10.5022 77.4031C9.47887 77.9657 8.52354 78.5068 7.64242 79.0231C6.7582 79.5394 6.02548 79.9815 5.44733 80.3494Z" fill="#F9F9F9" />
                    <path d="M15.4613 64.1397C15.3809 64.8137 15.2758 65.4938 15.1491 66.1771C15.0223 66.8604 14.8615 67.4509 14.6637 67.9486L2.26917 66.4553C2.25371 66.1802 2.24135 65.8865 2.23516 65.5742C2.22898 65.262 2.23207 64.9435 2.24753 64.622C2.26299 64.3005 2.28154 63.982 2.30627 63.6667C2.33101 63.3513 2.36192 63.0545 2.39593 62.7701C2.48868 61.9972 2.63399 61.2861 2.83186 60.6368C3.02972 59.9876 3.28942 59.4373 3.62023 58.9859C3.94795 58.5345 4.34986 58.1975 4.82598 57.9811C5.3021 57.7647 5.86478 57.6966 6.51403 57.7739C7.11691 57.8481 7.61467 58.0522 8.00731 58.3923C8.40304 58.7324 8.69675 59.1745 8.89462 59.7217C9.24707 58.9797 9.69536 58.4541 10.2426 58.148C10.7898 57.8389 11.4514 57.7337 12.2243 57.8265C13.5383 57.9842 14.4658 58.5809 15.0068 59.6197C15.5479 60.6554 15.6994 62.1641 15.4613 64.1397ZM7.55592 64.7766L7.75379 63.138C7.85891 62.2538 7.81872 61.5458 7.62703 61.0109C7.43535 60.4761 7.01488 60.17 6.36563 60.0927C5.75039 60.0185 5.28355 60.2287 4.96201 60.7172C4.64048 61.2088 4.43025 61.8704 4.33131 62.7052C4.28803 63.0607 4.25711 63.3791 4.23547 63.6574C4.21383 63.9356 4.20456 64.1737 4.20456 64.3716L7.55592 64.7766ZM9.38001 64.9961L13.2662 65.466C13.3219 65.2094 13.3744 64.9373 13.4208 64.6436C13.4703 64.3499 13.5074 64.0809 13.5383 63.8367C13.597 63.3575 13.6187 62.9092 13.6063 62.4918C13.594 62.0714 13.529 61.7035 13.4146 61.385C13.2972 61.0666 13.1209 60.8007 12.8829 60.5935C12.6479 60.3833 12.3264 60.2566 11.9214 60.2071C11.1979 60.1205 10.6631 60.3246 10.3168 60.8161C9.97052 61.3108 9.74173 62.025 9.63043 62.9556L9.38001 64.9961Z" fill="#F9F9F9" />
                    <path d="M17.3719 53.1704L5.55859 48.1032L6.47373 45.973L14.3173 49.3367C13.869 48.6875 13.396 47.964 12.8982 47.1633C12.4005 46.3656 11.912 45.5494 11.4328 44.7178C10.9536 43.8861 10.5022 43.0699 10.0724 42.2661C9.64269 41.4623 9.28714 40.7326 9.0058 40.071L9.76017 38.3149L21.5734 43.3822L20.6676 45.4969L12.2119 41.8704C12.5767 42.5907 13.0002 43.3667 13.4825 44.2015C13.9648 45.0331 14.475 45.8679 15.0129 46.7057C15.5509 47.5405 16.0888 48.3567 16.6268 49.1543C17.1678 49.952 17.6779 50.6816 18.1602 51.3463L17.3719 53.1704Z" fill="#F9F9F9" />
                    <path d="M19.5947 27.6057C20.2099 28.1807 20.8345 28.7681 21.4652 29.371C22.0959 29.9739 22.6771 30.5891 23.2089 31.2136C23.7406 31.8382 24.1982 32.4781 24.5847 33.1305C24.9711 33.7828 25.2277 34.4506 25.3545 35.1277C25.4812 35.8048 25.4596 36.4973 25.2803 37.1991C25.1041 37.9009 24.7207 38.6182 24.1271 39.3478L22.405 38.2224C22.6555 37.8514 22.844 37.499 22.9646 37.1651C23.0883 36.8312 23.1501 36.5035 23.1563 36.1819C23.1625 35.8604 23.1099 35.5327 22.9986 35.1926C22.8873 34.8556 22.7235 34.5032 22.5009 34.1353C21.9846 33.2541 21.2116 32.3019 20.1821 31.2786C19.1526 30.2552 17.9623 29.1639 16.6082 28.0076L21.8207 21.8984L31.5997 30.2429L30.0693 32.036L21.8145 24.9932L19.5947 27.6057Z" fill="#F9F9F9" />
                    <path d="M43.2555 21.753C42.8319 21.4283 42.4053 21.1099 41.9755 20.7976C41.5458 20.4885 41.1161 20.1638 40.6863 19.8268L36.1601 22.8289C36.3023 23.3575 36.4353 23.88 36.5651 24.3963C36.6919 24.9126 36.8186 25.4259 36.9485 25.9329L34.9234 27.2778C34.5679 25.868 34.2464 24.5571 33.965 23.3513C33.6837 22.1456 33.4271 20.9924 33.1983 19.898C32.9695 18.8035 32.7655 17.7523 32.5861 16.7414C32.4068 15.7335 32.243 14.7287 32.0977 13.727L33.9558 12.4934C34.8214 13.0159 35.684 13.5569 36.5435 14.1165C37.403 14.673 38.2934 15.2728 39.2116 15.9097C40.1298 16.5466 41.0944 17.2268 42.1023 17.9533C43.1102 18.6798 44.1954 19.4837 45.3547 20.3586L43.2555 21.753ZM34.3639 15.2048C34.4875 15.9097 34.6607 16.7537 34.874 17.7369C35.0904 18.7231 35.3377 19.7836 35.616 20.9182L39.1405 18.5809C38.2006 17.8822 37.3164 17.2391 36.4786 16.6486C35.6438 16.0643 34.9389 15.582 34.3639 15.2048Z" fill="#F9F9F9" />
                </g>
                <defs>
                    <clipPath id="clip0_349_241">
                        <rect width="140" height="140" fill="white" />
                    </clipPath>
                </defs>
            </svg>


            <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" className="center-item">
                <g clipPath="url(#clip0_606_257)">
                    <path d="M26.7433 35.4517C26.7433 30.6409 30.6428 26.7414 35.4536 26.7414H51.7049C51.7124 26.4946 51.7161 26.2477 51.7161 25.999C51.7161 25.7503 51.7124 25.5034 51.7049 25.2566H35.4536C30.6428 25.2566 26.7433 21.3571 26.7433 16.5463V0.296781C26.4964 0.289357 26.2496 0.285645 26.0009 0.285645C25.7522 0.285645 25.5053 0.289357 25.2584 0.296781V16.5481C25.2584 21.3589 21.3589 25.2584 16.5481 25.2584H0.296781C0.289357 25.5053 0.285645 25.7522 0.285645 26.0009C0.285645 26.2496 0.289357 26.4964 0.296781 26.7433H16.5481C21.3589 26.7433 25.2584 30.6428 25.2584 35.4536V51.7049C25.5053 51.7124 25.7522 51.7161 26.0009 51.7161C26.2496 51.7161 26.4964 51.7124 26.7433 51.7049V35.4517Z" fill="#2D2E34" />
                </g>
                <defs>
                    <clipPath id="clip0_606_257">
                        <rect width="51.4286" height="51.4286" fill="white" transform="translate(0.285645 0.285645)" />
                    </clipPath>
                </defs>
            </svg>

        </StyledAnimationSpinner>
    )
}

export default AnimationSpinner;