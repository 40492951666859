export const faq_header = <svg width="289" height="68" viewBox="0 0 289 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M80.9584 0V57.8825H63.4441V48.3058C56.8986 53.2323 45.9935 58.8496 30.3106 58.8496C10.3637 58.8496 0.163239 49.8177 0 31.9986L0.19509 0H17.805V25.6944C17.805 38.662 22.7101 43.7148 35.2994 43.7148C46.6504 43.7148 58.4753 37.8962 63.4441 32.2355V0H80.9584Z" fill="#161616" />
    <path d="M80.9584 57.8826H63.4441V67.8501H80.9584V57.8826Z" fill="#161616" />
    <path d="M167.578 0.150146H111.854H94.2604V68.0001H111.854V14.8823H167.578V26.7091V41.4412V68.0001H185.176V0.150146H167.578Z" fill="#161616" />
    <path d="M271.402 0.150146H215.678H198.08V68.0001H215.678V14.8823H271.402V26.7091V41.4412V68.0001H289V0.150146H271.402Z" fill="#161616" />
</svg>

export const faq_header_mobile = <svg width="164" height="38" viewBox="0 0 164 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_510_1090)">
        <path d="M45.9418 0V32.3461H36.0029V26.9944C32.2885 29.7475 26.1001 32.8866 17.2005 32.8866C5.88111 32.8866 0.0926337 27.8393 0 17.8816L0.110709 0H10.1038V14.3586C10.1038 21.6052 12.8874 24.4289 20.0315 24.4289C26.4729 24.4289 33.1832 21.1773 36.0029 18.0139V0H45.9418Z" fill="black" />
        <path d="M45.9421 32.3459H36.0032V37.916H45.9421V32.3459Z" fill="black" />
        <path d="M95.0966 0.0839844H63.4746H53.4905V38.0002H63.4746V8.31666H95.0966V14.9257V23.1584V38.0002H105.083V0.0839844H95.0966Z" fill="black" />
        <path d="M154.014 0.0839844H122.392H112.405V38.0002H122.392V8.31666H154.014V14.9257V23.1584V38.0002H164V0.0839844H154.014Z" fill="black" />
    </g>
    <defs>
        <clipPath id="clip0_510_1090">
            <rect width="164" height="38" fill="white" />
        </clipPath>
    </defs>
</svg>
