export const exclamationMarkIcon = <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30 55C31.6569 55 33 53.6569 33 52C33 50.3431 31.6569 49 30 49C28.3431 49 27 50.3431 27 52C27 53.6569 28.3431 55 30 55Z" fill="#005EFF" />
    <path d="M30 58C33.3137 58 36 55.3137 36 52C36 48.6863 33.3137 46 30 46C26.6863 46 24 48.6863 24 52C24 55.3137 26.6863 58 30 58Z" fill="url(#paint0_radial_1055_119)" />
    <path d="M31.7321 29.9891C30.9623 31.3224 29.0378 31.3224 28.268 29.9891L18.3037 12.7305C17.5339 11.3972 18.4961 9.73047 20.0357 9.73047H39.9643C41.5039 9.73047 42.4661 11.3972 41.6963 12.7305L31.7321 29.9891Z" fill="#005EFF" />
    <path d="M31.7324 38.9658C30.9626 40.2992 29.0381 40.2992 28.2683 38.9658L10.5297 8.2417C9.75991 6.90837 10.7222 5.2417 12.2618 5.2417H47.7389C49.2785 5.2417 50.2408 6.90837 49.471 8.2417L31.7324 38.9658Z" fill="url(#paint1_radial_1055_119)" />
    <defs>
        <radialGradient id="paint0_radial_1055_119" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.4565 43.9783) rotate(58.6315) scale(17.415)">
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint1_radial_1055_119" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(52.6203 50.2154) rotate(-121.369) scale(71.0612)">
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
    </defs>
</svg>
