export const hourglass = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.9999 13.0022C17.6825 13.0022 19.0466 11.6381 19.0466 9.95543C19.0466 8.27276 17.6825 6.90869 15.9999 6.90869C14.3172 6.90869 12.9531 8.27276 12.9531 9.95543C12.9531 11.6381 14.3172 13.0022 15.9999 13.0022Z" fill="#F2E635" />
    <path d="M16.9243 21.8476C16.5137 22.5587 15.4873 22.5587 15.0768 21.8476L5.61618 5.46133C5.20562 4.75022 5.71882 3.86133 6.53994 3.86133H25.4611C26.2822 3.86133 26.7954 4.75022 26.3849 5.46133L16.9243 21.8476Z" fill="url(#paint0_radial_1053_105)" />
    <path d="M15.0759 15.4724C15.4865 14.7612 16.5129 14.7612 16.9235 15.4724L22.2377 24.677C22.6483 25.3881 22.1351 26.277 21.314 26.277H10.6854C9.86432 26.277 9.35113 25.3881 9.76169 24.677L15.0759 15.4724Z" fill="#F2E635" />
    <path d="M15.0758 10.6852C15.4864 9.97407 16.5128 9.97407 16.9233 10.6852L26.3839 27.0714C26.7945 27.7825 26.2813 28.6714 25.4601 28.6714H6.53896C5.71784 28.6714 5.20464 27.7825 5.6152 27.0714L15.0758 10.6852Z" fill="url(#paint1_radial_1053_105)" />
    <defs>
        <radialGradient id="paint0_radial_1053_105" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.9903 2.4105) rotate(108.138) scale(22.137)">
            <stop stopColor="#F9F39A" />
            <stop offset="1" stopColor="#F9F39A" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint1_radial_1053_105" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(5.55357 28.0185) rotate(-17.9279) scale(23.3305)">
            <stop stopColor="#F9F39A" />
            <stop offset="1" stopColor="#F9F39A" stopOpacity="0" />
        </radialGradient>
    </defs>
</svg>


export const hourglass_red = <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.9998 16.2526C22.1032 16.2526 23.8083 14.5475 23.8083 12.4442C23.8083 10.3408 22.1032 8.63574 19.9998 8.63574C17.8965 8.63574 16.1914 10.3408 16.1914 12.4442C16.1914 14.5475 17.8965 16.2526 19.9998 16.2526Z" fill="#FF003A" />
    <path d="M21.1556 27.3099C20.6424 28.1988 19.3594 28.1988 18.8462 27.3099L7.02047 6.82715C6.50727 5.93826 7.14877 4.82716 8.17517 4.82716L31.8267 4.82715C32.8531 4.82715 33.4946 5.93826 32.9814 6.82715L21.1556 27.3099Z" fill="url(#paint0_radial_1055_112)" />
    <path d="M18.846 19.3405C19.3592 18.4516 20.6422 18.4516 21.1554 19.3405L27.7982 30.8463C28.3114 31.7352 27.6699 32.8463 26.6435 32.8463H13.3578C12.3314 32.8463 11.6899 31.7352 12.2031 30.8463L18.846 19.3405Z" fill="#FF003A" />
    <path d="M18.8452 13.3561C19.3584 12.4673 20.6414 12.4673 21.1546 13.3561L32.9803 33.8389C33.4935 34.7278 32.852 35.8389 31.8256 35.8389H8.1742C7.14779 35.8389 6.50629 34.7278 7.01949 33.8389L18.8452 13.3561Z" fill="url(#paint1_radial_1055_112)" />
    <defs>
        <radialGradient id="paint0_radial_1055_112" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.9882 3.01362) rotate(108.138) scale(27.6713)">
            <stop stopColor="#FF003A" />
            <stop offset="1" stopColor="#FF003A" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint1_radial_1055_112" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.94245 35.0228) rotate(-17.9279) scale(29.1632)">
            <stop stopColor="#FF003A" />
            <stop offset="1" stopColor="#FF003A" stopOpacity="0" />
        </radialGradient>
    </defs>
</svg>
