
//npm imports
import { useContext } from 'react';

//npm-imports
import { HeaderMedium28, Subheader18, Text18 } from '../../../../Components/Text/Text';
import styled, { withTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

//component imports
import Wrapper from '../../../../Components/Wrapper/Wrapper';
import AnimationSpinner from '../../../../Components/AnimationSpinner/AnimationSpinner';

//other imports
import { GlobalContext } from '../../../../Context/GlobalContext';
import { rules_header } from "./Static/rules-header.js";
import { rules_header_mobile } from "./Static/rules-header.js";
import { check_sign, hourglass, questionmark, voucher, star } from './Static/rules-icons';
import background from './Static/background.svg';

//styled-components
const RulesWrapper = styled.section`
    background-color: ${props => props.theme.lightBlack};
    padding-top: 80px;
    padding-bottom: 100px;
    background-image: url(${background});
    background-size: cover;
    position: relative;
    z-index: 1;
    .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 52px;
        .rules-header-mobile{
            display: none;
        }

        .rules-spinner{
            .center-item{
                path{
                    fill: ${props => props.theme.yellow};
                }
            }
        }
    }
    .rules-container{
        .rule-container{
            max-width: 683px;
            margin-bottom: 72px;
        }
        &>${Text18}{
            display: flex;
            align-items: center;
            svg{
                margin-right: 8px;
            }
        }
        .rules-cta-container{
            margin-top: 73px;
            display: flex;
            justify-content: flex-end;
                h5{

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    
                }
                &:hover{
                    cursor: pointer;
                    h5{
                        color: ${props => props.theme.yellow};
                        svg{
                            path{
                                fill: ${props => props.theme.yellow};
                            }
                        }
                    }
                }
            
        }
    }
    @media (max-width: 586px){
        padding-top: 123px;
        padding-bottom: 32px;   
        .header{
            margin-bottom: 22px;
            .rules-header{
                svg{
                    width: 200px;
                }
            }   
            .rules-spinner{
                width: 61px;
                height: 61px;
                &>.rules-home{
                    width: 100%;
                    height: 100%;
                }
                &>.center-item{
                    width: 24px;
                }
            }
        }
        .rules-container{
            .rule-container{
                margin-bottom: 55px;
                h4{
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 20px;
                }
            }
            &>p{
                margin-top: 100px;
                margin-bottom: 16px;
            }
            .rules-cta-container{
                margin-top: 0px;
                padding-top: 32px;
                border-top: 1px solid ${props => props.theme.darkGray};
            }
        }
    }
`

//main component
const Rules = (props) => {
    const { t } = useTranslation();
    const { formatExpirationDate, showAllRulesPopup, setShowAllRulesPopup } = useContext(GlobalContext);

    const rules = [
        {
            id: 1,
            text: t("homepage.rules.rules.one_time_per_term"),
            icon: check_sign
        },
        {
            id: 2,
            text: t("homepage.rules.rules.assessment_duration"),
            icon: hourglass
        },
        {
            id: 3,
            text: t("homepage.rules.rules.assessment_questions"),
            icon: questionmark
        },
        {
            id: 4,
            text: t("homepage.rules.rules.voucher_expires"),
            icon: voucher
        }
    ];

    return (
        <RulesWrapper>

            <Wrapper className='header'>
                <div className="rules-header">
                    {rules_header}
                </div>
                <div className="rules-header-mobile">
                    {rules_header_mobile}
                </div>
                <AnimationSpinner animationType="rules-home" className="rules-spinner" />
            </Wrapper>
            <Wrapper className='rules-container'>
                {rules.length > 0 && rules.map(rule => {
                    return (
                        <div key={rule.id} className="rule-container">
                            {rule.icon}
                            <HeaderMedium28 color={props.theme.white}>{rule.text.replace("{EXPIRATION_DATE}", () => formatExpirationDate())}</HeaderMedium28>
                        </div>
                    )
                })}
                <Text18 color={props.theme.white}>{star}{t("homepage.rules.voucher_validity_note")}</Text18>
                <div className="rules-cta-container">
                    <Subheader18 color={props.theme.white} onClick={() => setShowAllRulesPopup({ ...showAllRulesPopup, display: true })}>
                        {t("homepage.rules.cta")}
                        <svg width="62" height="13" viewBox="0 0 62 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5.75C0.585786 5.75 0.25 6.08579 0.25 6.5C0.25 6.91421 0.585786 7.25 1 7.25L1 5.75ZM61.5303 7.03033C61.8232 6.73744 61.8232 6.26256 61.5303 5.96967L56.7574 1.1967C56.4645 0.903806 55.9896 0.903806 55.6967 1.1967C55.4038 1.48959 55.4038 1.96447 55.6967 2.25736L59.9393 6.5L55.6967 10.7426C55.4038 11.0355 55.4038 11.5104 55.6967 11.8033C55.9896 12.0962 56.4645 12.0962 56.7574 11.8033L61.5303 7.03033ZM1 7.25L61 7.25V5.75L1 5.75L1 7.25Z" fill="white" />
                        </svg>
                    </Subheader18>
                </div>
            </Wrapper>
        </RulesWrapper>
    )
}

export default withTheme(Rules);