
//main component
const BrainsterLogo = ({ color, className }) => {

    return (
        <svg className={className} width="100" height="13" viewBox="0 0 100 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.44235 6.00623C8.30887 5.93949 8.30887 5.80602 8.44235 5.73928C9.17647 5.23875 9.61026 4.50463 9.61026 3.43682C9.61026 1.56816 8.34224 0.233398 5.83957 0.233398H0.367059C0.166845 0.233398 0 0.400243 0 0.600457V11.5455C0 11.7457 0.166845 11.9125 0.367059 11.9125H6.34011C8.7093 11.9125 10.1442 10.5444 10.1442 8.60901C10.1108 7.34099 9.47679 6.50677 8.44235 6.00623ZM3.00321 3.23661C3.00321 3.06976 3.13668 2.93629 3.30353 2.93629H5.63936C6.27337 2.93629 6.57369 3.30334 6.57369 3.87062C6.57369 4.43789 6.27337 4.80495 5.47251 4.80495H3.30353C3.13668 4.80495 3.00321 4.67147 3.00321 4.50463V3.23661ZM5.97305 9.17628H3.30353C3.13668 9.17628 3.00321 9.04281 3.00321 8.87596V7.40773C3.00321 7.24088 3.13668 7.10741 3.30353 7.10741H6.00642C6.80727 7.10741 7.10759 7.50783 7.10759 8.10848C7.10759 8.74249 6.80727 9.17628 5.97305 9.17628Z" fill={color} />
            <path d="M20.0878 8.60918C19.9877 8.4757 20.0211 8.30886 20.1879 8.20875C21.5894 7.44126 22.2234 6.0064 22.2234 4.47142C22.2234 2.1356 20.8553 0.200195 17.8521 0.200195H12.5131C12.3128 0.200195 12.146 0.36704 12.146 0.567254V11.5123C12.146 11.7125 12.3128 11.8793 12.5131 11.8793H14.7821C14.9824 11.8793 15.1492 11.7125 15.1492 11.5123V9.04298C15.1492 8.87613 15.2827 8.74265 15.4495 8.74265H16.3505C16.5173 8.74265 16.7175 8.84276 16.8177 8.97624L18.6529 11.579C18.7864 11.7459 18.9866 11.8793 19.2202 11.8793H22.09C22.2234 11.8793 22.3235 11.7125 22.2234 11.579L20.0878 8.60918ZM17.6852 6.07314H15.4162C15.2493 6.07314 15.1158 5.93966 15.1158 5.77281V3.23677C15.1158 3.06993 15.2493 2.93645 15.4162 2.93645H17.6852C18.8865 2.93645 19.3203 3.60383 19.3203 4.50479C19.3537 5.33902 18.8865 6.07314 17.6852 6.07314Z" fill={color} />
            <path d="M31.0662 0.567088C30.9995 0.366874 30.7659 0.233398 30.5657 0.233398H28.33C28.1298 0.233398 27.8962 0.366874 27.8294 0.567088L23.358 11.5789C23.2913 11.7123 23.3914 11.9125 23.5916 11.9125H26.1276C26.3278 11.9125 26.528 11.7791 26.5948 11.5789L27.0286 10.4777C27.0953 10.3108 27.2622 10.1774 27.429 10.1774H31.4333C31.6001 10.1774 31.767 10.3108 31.8337 10.4777L32.2675 11.5789C32.3342 11.7791 32.5345 11.9125 32.7347 11.9125H35.2707C35.4376 11.9125 35.571 11.7457 35.5043 11.5789L31.0662 0.567088ZM30.4322 7.47447H28.4634C28.33 7.47447 28.2299 7.34099 28.2632 7.17415L29.3644 4.33778C29.3978 4.23768 29.5313 4.23768 29.5646 4.33778L30.6324 7.20751C30.6658 7.34099 30.5991 7.47447 30.4322 7.47447Z" fill={color} />
            <path d="M39.9433 0.233398H37.6742C37.474 0.233398 37.3071 0.400243 37.3071 0.600457V11.5455C37.3071 11.7457 37.474 11.9125 37.6742 11.9125H39.9433C40.1435 11.9125 40.3103 11.7457 40.3103 11.5455V0.600457C40.3103 0.400243 40.1435 0.233398 39.9433 0.233398Z" fill={color} />
            <path d="M53.1907 0.233398H50.9217C50.7214 0.233398 50.5546 0.400243 50.5546 0.600457V6.4734C50.5546 6.5735 50.4211 6.60687 50.3877 6.54013L45.5826 0.533719C45.4491 0.366874 45.2156 0.233398 44.982 0.233398H43.18C42.9798 0.233398 42.813 0.400243 42.813 0.600457V11.5455C42.813 11.7457 42.9798 11.9125 43.18 11.9125H45.4491C45.6493 11.9125 45.8162 11.7457 45.8162 11.5455V5.67254C45.8162 5.57243 45.9497 5.53907 45.983 5.6058L50.7882 11.5789C50.9216 11.7457 51.1552 11.8792 51.3554 11.8792H53.1574C53.3576 11.8792 53.5244 11.7123 53.5244 11.5121V0.600457C53.5578 0.400243 53.391 0.233398 53.1907 0.233398Z" fill={color} />
            <path d="M60.8652 4.57155C59.4637 4.10438 58.9298 3.83743 58.9298 3.40364C58.9298 3.00321 59.3303 2.60278 60.3647 2.60278C61.3658 2.60278 61.933 3.03658 62.0999 3.53711C62.2 3.80406 62.3335 3.93754 62.6004 3.93754H64.6693C64.8695 3.93754 65.0697 3.73733 65.0697 3.50374C64.9362 1.43487 63.0342 0 60.298 0C57.6284 0 55.7264 1.4015 55.7264 3.63722C55.7264 6.03978 58.1623 6.94075 60.031 7.57476C61.4325 8.04192 62.0331 8.34224 62.0331 8.84278C62.0331 9.17647 61.6661 9.5769 60.5649 9.5769C59.5305 9.5769 58.8965 9.20984 58.6962 8.84278C58.5294 8.54246 58.4293 8.44235 58.0956 8.44235H56.0601C55.8599 8.44235 55.6597 8.64257 55.6597 8.87615C55.8599 11.0118 57.9621 12.1797 60.5983 12.1797C63.2678 12.1797 65.2366 10.9784 65.2366 8.7093C65.2366 6.10652 62.5003 5.13882 60.8652 4.57155Z" fill={color} />
            <path d="M76.5151 0.233398H66.9715C66.7713 0.233398 66.6045 0.400243 66.6045 0.600457V2.76944C66.6045 2.96965 66.7713 3.1365 66.9715 3.1365H69.9414C70.1082 3.1365 70.2417 3.26998 70.2417 3.43682V11.5455C70.2417 11.7457 70.4086 11.9125 70.6088 11.9125H72.8779C73.0781 11.9125 73.2449 11.7457 73.2449 11.5455V3.43682C73.2449 3.26998 73.3784 3.1365 73.5452 3.1365H76.5151C76.7153 3.1365 76.8821 2.96965 76.8821 2.76944V0.600457C76.8821 0.400243 76.7153 0.233398 76.5151 0.233398Z" fill={color} />
            <path d="M87.2933 0.233398H79.0511C78.8509 0.233398 78.6841 0.400243 78.6841 0.600457V11.5455C78.6841 11.7457 78.8509 11.9125 79.0511 11.9125H87.2933C87.4935 11.9125 87.6604 11.7457 87.6604 11.5455V9.3765C87.6604 9.17628 87.4935 9.00944 87.2933 9.00944H81.9876C81.8208 9.00944 81.6873 8.87596 81.6873 8.70912V7.80816C81.6873 7.64131 81.8208 7.50784 81.9876 7.50784H86.2255C86.4257 7.50784 86.5926 7.34099 86.5926 7.14078V5.13864C86.5926 4.93842 86.4257 4.77158 86.2255 4.77158H81.9876C81.8208 4.77158 81.6873 4.6381 81.6873 4.47126V3.40345C81.6873 3.23661 81.8208 3.10313 81.9876 3.10313H87.2933C87.4935 3.10313 87.6604 2.93629 87.6604 2.73607V0.600457C87.6604 0.400243 87.4935 0.233398 87.2933 0.233398Z" fill={color} />
            <path d="M99.9729 11.6122L97.8373 8.64238C97.7372 8.5089 97.7706 8.34206 97.9374 8.24195C99.3389 7.47447 99.9729 6.0396 99.9729 4.50463C99.9729 2.1688 98.6048 0.233398 95.6016 0.233398H90.2626C90.0624 0.233398 89.8955 0.400243 89.8955 0.600457V11.5455C89.8955 11.7457 90.0624 11.9125 90.2626 11.9125H92.5317C92.7319 11.9125 92.8987 11.7457 92.8987 11.5455V9.07618C92.8987 8.90933 93.0322 8.77586 93.199 8.77586H94.1334C94.3002 8.77586 94.5004 8.87596 94.6005 9.00944L96.4358 11.6122C96.5693 11.7791 96.7695 11.9125 97.0031 11.9125H99.8728C99.9729 11.9125 100.04 11.7457 99.9729 11.6122ZM95.4014 6.07297H93.1323C92.9655 6.07297 92.832 5.93949 92.832 5.77265V3.23661C92.832 3.06976 92.9655 2.93629 93.1323 2.93629H95.4014C96.6027 2.93629 97.0365 3.60367 97.0365 4.50463C97.0698 5.33885 96.6027 6.07297 95.4014 6.07297Z" fill={color} />
        </svg>
    )
}

export default BrainsterLogo;