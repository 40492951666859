import { useContext, useEffect } from 'react';

//npm imports
import { Link, useNavigate } from 'react-router-dom';
import styled, { withTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

//component imports
import { AssessmentWrapper } from '../Assessment/Assessment';
import CountdownTimer from '../../Components/CountdownTimer/CountdownTimer';
import { Header32, Text18 } from '../../Components/Text/Text';
import ButtonWide from '../../Components/ButtonWide/ButtonWide';
import BrainsterLogo from '../../Components/BrainsterLogo/BrainsterLogo';

//other imports
import { API } from '../../Consts/Api';
import { GlobalContext } from '../../Context/GlobalContext';
import { hourglass_red } from '../Assessment/Static/hourglass';
import { assessmentVouchersLogo } from '../Assessment/Static/assessment-vouchers-logo';
import result_page_background from '../Result/Static/result_background.svg';

//styled-components
const OngoingWrapper = styled(AssessmentWrapper)`
    &>form{

        .header{
            flex-direction: column;
            align-items: center;
            margin-bottom: 94px;
            .timer{
                margin-top: 130px;
                transform: scale(1.3);
            }
        }
        ${Header32}, ${Text18}{
            text-align: center;
        }
        
        ${Text18}{
            width: 80%;
            margin: 24px auto 129px auto;
        }
    }
    @media (max-width: 768px){
        &>form{
            width: 100%;
            padding: 0px 0 23px 0;
            .header{
                width: 100%;
                padding-top: 65px;
                padding-bottom: 0px;
                margin-bottom: 0px;
                background-color: ${props => props.theme.lightBlack};
                background-image: url(${result_page_background});
                background-size: 300%;
                background-position: center;
                .timer {
                    background-color: ${props => props.theme.lightestGray};
                    padding: 16px 32px;
                    border-radius: 100px;
                    margin-top: 137px;
                    margin-bottom: -30px;
                }
            }
            ${Header32}, ${Text18}, a{
                width: 90%;
                margin-left: auto;
                margin-right: auto;
            }
            ${Header32}{
                margin-top: 75px;
            }
            ${Text18}{
                margin: 24px auto 29px auto
            }
            .brainster-logo-container{
                margin-top: 60px;
            }
        }
    }
`

//main components
const Ongoing = (props) => {
    const { t } = useTranslation();
    const { duration, onTimeOutNavigateUserToResult } = useContext(GlobalContext);

    let navigate = useNavigate();

    useEffect(() => {

        if (typeof duration === "number" && duration >= 0) {
            onTimeOutNavigateUserToResult();
        }
        //eslint-disable-next-line
    }, [duration])


    useEffect(() => {
        //if user has no active assessment session, redirect user to homepage
        if (localStorage.getItem("guid") === null) {
            navigate(API.createRoute("home"));
        }
        //eslint-disable-next-line
    }, [])
    return (
        <OngoingWrapper duration={duration}>
            <form>

                <div className="countdown-progress">
                    <div className="countdown-progressbar"></div>
                </div>
                <div className="header">
                    {assessmentVouchersLogo}
                    <div className="timer">
                        <CountdownTimer duration={duration ? duration - 2 : duration} />
                        {hourglass_red}
                    </div>
                </div>
                <Header32>{t("ongoing.header")}</Header32>
                <Text18 color={props.theme.darkGray}>{t("ongoing.description")}</Text18>
                <Link to={API.createRoute("assessment")}><ButtonWide text={t("ongoing.resume_cta")} /></Link>
                <div className='brainster-logo-container'>
                    <Link to={API.createRoute("home")}>
                        <BrainsterLogo color={props.theme.gray} />
                    </Link>
                </div>
            </form>
        </OngoingWrapper >
    )
}

export default withTheme(Ongoing);