//npm imports
import styled, { css } from 'styled-components/macro';
import { ButtonText12, ButtonText14, ButtonText16 } from '../Text/Text';


//styled components
const StyledButtonBasic = styled.button`
    ${props => props.variation === "small" ? css`
        padding: 10px 20px;
    ` : props.variation === "large" ? css`
        padding: 14px 30px;
    ` : props.variation === "extra-large" && css`
        padding: 14px 30px;
    `}
    background-color: ${props => props.theme.black};
    border: none;
    &:hover{
        cursor: pointer;
    }
`

//main-component
const ButtonBasic = ({ onClick, text, variation }) => {
    return (
        <StyledButtonBasic variation={variation} onClick={onClick}>
            {variation === "small"
                ? <ButtonText12>{text}</ButtonText12>
                : variation === "large"
                    ? <ButtonText14>{text}</ButtonText14>
                    : variation === "extra-large"
                    && <ButtonText16>{text}</ButtonText16>
            }

        </StyledButtonBasic>
    )
}

export default ButtonBasic;