import { useContext } from 'react';

//npm imports
import styled, { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

//component imports
import { Text14, Text16, Text18 } from '../../Components/Text/Text';

//other imports
import hero_background from '../Homepage/Blocks/HomepageHero/Static/hero-background.svg';
import { vouchers_header, twoPointO } from './Static/vouchers-header';
import { GlobalContext } from '../../Context/GlobalContext';
import brainster_blog_background from '../Result/Static/brainster_blog_background.svg';
import brainster_blog_mobile_shapes from '../Result/Static/brainster_blog_mobile_shapes.svg';
import brainster_blog_shapes from '../Result/Static/brainster_blog_shape.svg';
import media_blog_shapes from '../Result/Static/media_blog_shapes.svg';
import BrainsterLogo from '../../Components/BrainsterLogo/BrainsterLogo';


//styled-components
const StyledAwardsPending = styled.section`
    width: 100%;
    min-height: 100vh;
    background-color: ${props => props.theme.gray};
    background-image: url(${hero_background});
    background-size: cover;
    background-position: center top;
    display: flex;
    justify-content: center;
    align-items: center;
    &>main{
        width: 60%;
        max-width: 850px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &>header{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;            
            &>div.vouchers-logo-header{
                margin-top: 45px;
                margin-bottom: 42px;
                display: flex;
                justify-content: space-between;
                &>svg:first-child{
                    width: 77%;
                }
                &>svg:last-child{
                    width: 20%;
                }
            }
            &>${Text16}{
                text-align: center;
                &, span, a{
                    user-select: text;
                }
                a{
                    color: ${props => props.theme.active}
                }
            }
            
        }
        &>div.border{
            width: 13px;
            height: 2px;
            margin-top: 59px;
            margin-bottom: 48px;
            background-color: ${props => props.theme.black};
        }
        &>section{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 90px;
            &>div.blogs-container{
                width: 100%;
                display: flex;
                gap: 9px;
                margin-top: 26px;
                &>a{
                    width: 50%;
                }
            }
        }
    }

    @media (max-width: 1100px){
        &>main{
            width: 75%;
        }
    }



    @media (max-width: 950px){
        &>main{
            width: 90%;
            &>section{
                width: 100%;
            }
        }
    }


    @media (max-width: 550px){
        &>main{
            width: 90%;
            .brainster-logo{
                order: 1;
                margin-top: 63px;
                margin-bottom: 80px;
            }
            &>header{
                order: 2;
                ${Text16}{
                    align-self: flex-start;
                    text-align: left;
                    font-size: 14px;
                    line-height: 16px;
                }
                &>div.vouchers-logo-header{
                    margin-top: 25px;
                    margin-bottom: 21px;
                    flex-direction: column;
                    &>svg:first-child{
                        width: 100%;
                    }
                    &>svg:last-child{
                        width: 28%;
                    }
                }
            }
            &>div.border{
                align-self: flex-start;
                order: 3;
                margin: 65px 0;
            }
            &>section{
                order: 4;
                width: 100%;
                justify-content: flex-start;
                align-items: flex-start;
                &>div.blogs-container{
                    flex-direction: column;
                    a{
                        width:  100%;
                    }
                }
            }
        }
    }

    @media (max-width: 450px){
        &>main{
            &>header{
                &>div.vouchers-logo-header{
                    &>svg{
                        height: 45px;
                    }
                }
            }
        }
    }

`

const BlogCta = styled.div`
    width: 315px;
    height: 126px;
    border-radius: 10px;
    padding: 18px 0 0 14px;
    position: relative;
    background-image: url(${props => props.background && props.background});
    background-size: cover;
    background-repeat: no-repeat;
    ${Text18}{
        font-family: 'MonumentExtended';
    }
    img{
        position: absolute;
    }
    .block-mobile-shape {
        display: none;
    }
    &.brainster_blog{
        background-color: ${props => props.theme.altYellow};
        img{
            top: -10px;
            right: 5px;
        }
    }
    &.media_blog{
        background-color: ${props => props.theme.lightBlack};
        img{
            bottom: -15px;
            right: 0px;
        }
    }
    @media (max-width: 950px){
        width: 100%;
    }
    @media (max-width: 550px){
        height: 130px;
        .block-mobile-shape{
            display: block;
        }
        .media_blog{

            &.block-shape{
                display: none;
            }
        }
    }
`

//main component
const AwardsPending = (props) => {
    const { t } = useTranslation();
    const { term, formatResultsDate, checkDatesForDisplayingAwards } = useContext(GlobalContext);

    const ctaBlogs = [
        {
            id: 1,
            link: "https://blog.brainster.co/",
            title: t("result.cta.brainster_blog").replace("Блог", "Blog"),
            color: props.theme.black,
            class: "brainster_blog",
            shape: brainster_blog_shapes,
            mobile_shape: brainster_blog_mobile_shapes,
            background: brainster_blog_background
        },
        {
            id: 2,
            link: "https://media.next.edu.mk/",
            title: t("result.cta.media_blog"),
            color: props.theme.white,
            class: "media_blog",
            shape: media_blog_shapes,
        },


    ]
    return (
        <StyledAwardsPending>
            <main>
                <header>
                    <Text16 fontWeight={700}>{t('awards_pending.header.the_term')}</Text16>
                    <div className="vouchers-logo-header">
                        {vouchers_header}
                        {twoPointO}
                    </div>
                    <Text16 fontWeight={700} className='header-description'>
                        {
                            term.results_date
                                && term.end_date
                                && checkDatesForDisplayingAwards({ endDate: new Date(term.end_date) }, new Date())
                                && !checkDatesForDisplayingAwards({ resultsDate: new Date(term.results_date) }, new Date())
                                ? <span>{t('awards_pending.header.is_finished')}</span>
                                : term.results_date
                                && term.end_date
                                && !checkDatesForDisplayingAwards({ endDate: new Date(term.end_date) }, new Date())
                                && <span>{t('awards_pending.header.in_progress')}</span>
                        }

                        <span>{
                            t('awards_pending.header.results').split("$1").map((text, index) => {
                                if (text === "{DOMAIN}{AWARDS_ROUTE}") {
                                    return (
                                        <a key={index} href={process.env.REACT_APP_DOMAIN_URL + "awards"}>{text.replace("{DOMAIN}{AWARDS_ROUTE}", process.env.REACT_APP_DOMAIN_URL + "awards")}</a>
                                    )
                                } else {
                                    return (
                                        text.replace("{RESULTS_DATE}", formatResultsDate(term.results_date))
                                    )
                                }
                            })
                        }</span>
                    </Text16>
                </header>
                <div className="border"></div>
                <section>
                    <Text14 fontWeight={700}>{t("awards_pending.cta.header")}</Text14>
                    <div className="blogs-container">
                        {ctaBlogs.length > 0 && ctaBlogs.map(blog => (
                            <a key={blog.id} href={blog.link} target="_blank" rel="noreferrer">
                                <BlogCta className={`${blog.class} blog`} background={blog.background}>
                                    <Text18 fontWeight={700} color={blog.color}>{blog.title.split(" ").map((text, index) => <span key={index}>{text}<br /></span>)}</Text18>
                                    <img src={blog.shape} className='block-shape' alt="shape" />
                                    {blog.mobile_shape
                                        && <img src={blog.mobile_shape} className='block-mobile-shape' alt="shape" />
                                    }
                                </BlogCta>
                            </a>
                        ))}
                    </div>
                </section>
                <BrainsterLogo color={props.theme.black} className={"brainster-logo"} />
            </main>
        </StyledAwardsPending>
    )
}

export default withTheme(AwardsPending);