import { Routes, Route } from "react-router-dom";

//pages imports
import Homepage from './Pages/Homepage/Homepage';
import Assessment from './Pages/Assessment/Assessment'
import RulesAgreement from "./Pages/RulesAgreement/RulesAgreement";
import ApplicationForm from "./Pages/ApplicationForm/ApplicationForm";
import Ongoing from "./Pages/Ongoing/Ongoing";
import Result from "./Pages/Result/Result";

//npm imports
import { ThemeProvider } from 'styled-components'

//other imports
import theme from './Consts/Theme';
import GlobalStyle from "./Consts/GlobalStyle";
import AllRulesPopup from "./Components/AllRulesPopup/AllRulesPopup";
import Awards from "./Pages/Awards/Awards";
import AwardsPending from "./Pages/AwardsPending/AwardsPending";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes>
        <Route index path="/" element={<Homepage />} />
        <Route index path="/reffer/:referralGuid" element={<Homepage />} />
        <Route path="/assessment" element={<Assessment />} />
        <Route path="/assessment/apply/:academyId" element={<ApplicationForm />} />
        <Route path="/assessment/apply/reffer/:referralGuid/:academyId" element={<ApplicationForm />} />
        <Route path="/assessment/confirm/:academyId" element={<RulesAgreement />} />
        <Route path="/assessment/ongoing" element={<Ongoing />} />
        <Route path="/result/:guid" element={<Result />} />
        <Route path="/awards/:academyId" element={<Awards />} />
        <Route path="/awards/:academyId/:token" element={<Awards />} />
        <Route path="/awards/" element={<Awards />} />
        <Route path="/pending" element={<AwardsPending />} />
      </Routes>
      <AllRulesPopup />
    </ThemeProvider>
  );
}

export default App;
