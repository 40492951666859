
//npm imports
import styled, { withTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

//component imports
import { Text14 } from '../Text/Text';
import Wrapper from '../Wrapper/Wrapper';

//other imports
import { facebook_footer_icon, linkedin_footer_icon, instagram_footer_icon } from './Static/footer-icons';
import { Link } from 'react-router-dom';

//styled-components
const FooterSimpleWrapper = styled.footer`
    width: 100%;
    height: 60px;
    background-color: ${props => props.theme.black};
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    ${Text14}{
        color: ${props => props.theme.danger_select};
    }
    &>div{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .brainster-original{
            
        }
        .follow-us{
            display:flex;
            align-items: center;
            svg{
                margin-left: 15px;
            }
        }
    }
    @media (max-width: 500px){
        height: unset;
        padding: 20px 0;
        &>div{
            flex-direction: column-reverse;
            align-items: center;
            .follow-us{
                margin-bottom: 15px;
            }
        }
    }
`

const socialMedia = [
    {
        icon: facebook_footer_icon,
        link: "https://www.facebook.com/brainster.co"
    },
    {
        icon: instagram_footer_icon,
        link: "https://www.instagram.com/brainsterco/"
    },
    {
        icon: linkedin_footer_icon,
        link: "https://www.linkedin.com/school/brainster-co/mycompany/"
    }
]

//component
const FooterSimple = (props) => {
    const { t } = useTranslation();
    return (
        <FooterSimpleWrapper >
            <Wrapper>

                <div className="brainster-original">
                    <Text14 color={props.theme.darkGray}>&copy; {t("footer.brainster_original")} &hearts;</Text14>
                </div>
                <div className="follow-us">
                    <Text14 color={props.theme.darkGray}>{t("footer.follow_us")}</Text14>
                    {socialMedia.map((network, index) => {
                        return (
                            <a key={index} href={network.link} target="_blank" rel="noopener noreferrer" className="social-network-container">
                                {network.icon}
                            </a>
                        )
                    })}
                </div>
            </Wrapper>
        </FooterSimpleWrapper>

    );
}

export default withTheme(FooterSimple);