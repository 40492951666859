import { useContext } from 'react';
import { GlobalContext } from '../../../../Context/GlobalContext'

//npm imports
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

//hooks
import useArrayLength from '../../../../Hooks/useArrayLength';

//component imports
import AcademyCard from '../../../../Components/AcademyCard/AcademyCard';
import Wrapper from '../../../../Components/Wrapper/Wrapper';
import { HeaderLarge40 } from '../../../../Components/Text/Text';
import AnimationSpinner from '../../../../Components/AnimationSpinner/AnimationSpinner';

//other imports
import { API } from '../../../../Consts/Api';
import { forwardRef } from 'react';

const ChooseAcademyWrapper = styled.section`
    background-color: ${props => props.theme.veryLightGray};
    padding-top: 80px;
    position: relative;
    z-index:2;
    .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;
    }
    .cards-container{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
        .card-container{
            margin-bottom: -32px;
            margin-top: 32px;
        }
    }

    @media (max-width: 1005px){
        .cards-container{
            grid-template-columns: 1fr 1fr;
        }
    }

    @media (max-width: 665px){
        .header{
            width: 80%;
        }
        .cards-container{
            grid-template-columns: 1fr;
            width: 80%;
        }
    }


    @media (max-width: 586px){
        .header{
            width: 90%;
        }
        .cards-container{
            grid-template-columns: 1fr;
            width: 90%;
        }
        ${HeaderLarge40}{
            font-size: 24px;
            line-height: 28px;
        }
        .rules-spinner{
            width: 61px;
            height: 61px;
            &>.academy-list{
                width: 100%;
                height: 100%;
            }
            &>.center-item{
                width: 24px;
            }
        }
    }

    @media (max-width: 425px){
        padding-top: 60px;
        .cards-container{
            width: 95%;
        }
    }
`


const ChooseAcademy = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { academies, userInfo } = useContext(GlobalContext);
    return (
        <ChooseAcademyWrapper ref={ref}>
            <Wrapper className="header">
                <HeaderLarge40>{t("homepage.choose_academy.header")}</HeaderLarge40>
                <AnimationSpinner animationType="academy-list" className="rules-spinner" />
            </Wrapper>
            <Wrapper className="cards-container">
                {useArrayLength(academies) && academies.map((academy, index) => (
                    <div className="card-container" key={index}>
                        <AcademyCard
                            href={userInfo.academy_id ? API.createRoute("ongoing") : userInfo.referred_by !== null ? API.createRoute("applyWithReferral", { REFERRAL_GUID: userInfo.referred_by, ACADEMY_ID: academy.id }) : API.createRoute("apply", { ACADEMY_ID: academy.id })}
                            moreInfoUrl={academy.url}
                            title={academy.name}
                            borderColor={academy.code}
                            academyDuration={academy.length}
                            startDate={academy.start_date}
                        />
                    </div>

                ))}
            </Wrapper>
        </ChooseAcademyWrapper>
    )
})
export default ChooseAcademy;