import { useContext } from 'react';

//npm imports
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import Accordion from '../../../../Components/Accordion/Accordion';

//component imports
import Wrapper from '../../../../Components/Wrapper/Wrapper';

//other imports
import { faq_header, faq_header_mobile } from './Static/faq-header'
import { GlobalContext } from '../../../../Context/GlobalContext';
import faq_background from './Static/faq-background.svg';

//styled-components
const StyledFaq = styled.div`
    background-color: ${props => props.theme.white};
    background-image: url(${faq_background});
    background-size: cover;
    background-position: center;
    padding: 120px 0;
    position: relative;
    z-index: 2;
    svg{
        margin-bottom: 74px;
    }
    .faq-header-mobile{
        display: none;
    }
    @media (max-width: 586px){
        padding: 80px 0;
        background-image: none;
        .faq-header{
            display: none;
        }
        .faq-header-mobile{
            display: block;
        }
    }
`

//main component
const Faq = (props) => {

    const { t } = useTranslation();
    const { formatExpirationDate } = useContext(GlobalContext);

    return (
        <StyledFaq>
            <Wrapper>
                <div className="faq-header">
                    {faq_header}
                </div>
                <div className="faq-header-mobile">
                    {faq_header_mobile}
                </div>
                <div className="faq-container">
                    {Array(12).fill().map((question, index) => <Accordion key={index} propShowMore={index === 0} header={t(`homepage.faq.${index}.question`)} content={t(`homepage.faq.${index}.answer`).replace("{EXPIRATION_DATE}", () => formatExpirationDate())} />)}
                </div>
            </Wrapper>
        </StyledFaq>
    )
}

export default Faq;