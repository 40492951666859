
//npm imports
import styled, { keyframes, css, withTheme } from 'styled-components/macro';
import { Text14 } from '../Text/Text';

const slideArrowIn = keyframes`
    0%{
        transform: translate(-100%, 0);
    }
    100%{   
        transform: translate(0%, 0)
    }
`

//styled-components
export const StyledButtonWide = styled.button`
    width: 100%;
    height: 55px;
    background-color: ${props => props.theme.black};
    outline: none;
    text-align: left;
    padding-left: 24px;
    padding-right: 24px;
    border: none;
    ${Text14}{
        color: ${props => props.theme.white};
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    .arrow-container{
        overflow: hidden;
    }
    &:hover{
        svg{
            ${props => !props.disabled && css`
                animation: ${slideArrowIn} .15s linear forwards;
            ` }
        }
        background-color: ${props => props.disabled ? props.theme.darkGray : props.theme.lightBlack};
        cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
    }
`

//main component
const ButtonWide = ({ text, onClick, type, disabled, theme }) => {
    return (
        <StyledButtonWide type={type} onClick={onClick} disabled={disabled}>
            <Text14 fontWeight={700}>{text}</Text14>
            <div className="arrow-container">
                <svg width="62" height="13" viewBox="0 0 62 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5.75C0.585786 5.75 0.25 6.08579 0.25 6.5C0.25 6.91421 0.585786 7.25 1 7.25L1 5.75ZM61.5303 7.03033C61.8232 6.73744 61.8232 6.26256 61.5303 5.96967L56.7574 1.1967C56.4645 0.903806 55.9896 0.903806 55.6967 1.1967C55.4038 1.48959 55.4038 1.96447 55.6967 2.25736L59.9393 6.5L55.6967 10.7426C55.4038 11.0355 55.4038 11.5104 55.6967 11.8033C55.9896 12.0962 56.4645 12.0962 56.7574 11.8033L61.5303 7.03033ZM1 7.25L61 7.25V5.75L1 5.75L1 7.25Z" fill={theme.yellow} />
                </svg>
            </div>
        </StyledButtonWide>
    )
}

export default withTheme(ButtonWide);