//npm imports
import { useCallback, useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components/macro';

//component imports
import BrainsterLogo from '../../../../Components/BrainsterLogo/BrainsterLogo';
import ButtonBasic from '../../../../Components/ButtonBasic/ButtonBasic';
import { MonumentHeader24, Text16 } from '../../../../Components/Text/Text';
import Wrapper from '../../../../Components/Wrapper/Wrapper';

//other imports
import { GlobalContext } from '../../../../Context/GlobalContext';
import hero_background from './Static/hero-background.svg';
import { vouchers_vou, vouchers_chers, twoPointO, arrow_down } from './Static/vouchers_header.js';
import { voucher_card } from './Static/voucher_card';

const HomepageHeroWrapper = styled.section`
    width: 100%;
    height: 100vh;
    background-color: ${props => props.theme.gray};
    background-image: url(${hero_background});
    background-size: cover;
    background-position: center top;
    position: sticky;
    top:0;
    z-index: 0;
    overflow: hidden;
    svg:nth-child(3){
        width: 21.5%!important;
        position: absolute;
        bottom: ${props => props.scrollPosition * 3.6}px;
        z-index: 2;
        transition: bottom .2s ease-out;
        left:  33%;
        transform: translateY(55vh);
    }
    &>div{
        height: 100%;
        padding: 40px 0 60px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            button{
                &:hover{
                    transform: scale(1.02);
                }
            }
        }
        &>.vouchers-main-content{
            width: 100%;
            max-width: 1100px;
            height: max-content;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .vouchers-header-container{
                display: grid;
                grid-template-columns: 40.3% 57.5%;
                gap: 1.9%;
                &>svg{
                    width: 100%;   
                    
                }
                &>svg:first-child{
                    z-index: 2;
                    position: relative;

                }
                &>svg:nth-child(2){
                    position: relative;
                    z-index: 4;

                }
            }
            .vouchers-description-container{
                margin-top: 33px;
                display: grid;
                grid-template-columns: 50% 1fr 26.5%;
                gap: 1.9%;
                &>div{
                    &>h3{
                        margin-bottom: 8px;
                    }
                }
                &>svg{
                    width: 100%;
                    order: 3;
                    grid-column: 3/3;
                    align-self: flex-start;
                }
            }
        }
        .cta-arrow-container{
            width: max-content;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-self: center;
            &>p{
                margin-bottom: 16px;
            }
            cursor: pointer;
        }
    }
    @media (max-width: 1200px){
        &>div{
            &>.vouchers-main-content{
                width: 90%;
                .vouchers-header-container{
                }
                .vouchers-description-container{
                    
                }

            }
        }
    }
    @media (max-width: 768px){

    svg:nth-child(3){
        width: 28%!important;
        bottom: ${props => props.scrollPosition * 1.6}px;
    }
        &>div{
            header{
                
            }
            &>.vouchers-main-content{

                .vouchers-header-container{
                }
                .vouchers-description-container{
                    margin-top:-50px;
                    &>div{
                        display: none;
                    }
                }
            }
        }
    }
    @media (max-width: 550px){

        svg:nth-child(3){
        width: 33%!important;
        }
        &>div{
            header{
                
            }
            &>.vouchers-main-content{

                .vouchers-header-container{
                }
                .vouchers-description-container{
                    margin-top:-70px;
                }
            }
        }
    }

    @media (max-width: 400px){
        &>div{
            header{
                
            }
            &>.vouchers-main-content{

                .vouchers-header-container{
                }
                .vouchers-description-container{
                    margin-top:-80px;
                }
            }
        }
    }
`

const HomePageHero = ({ scrollChooseAcademyIntoView, theme }) => {
    const { t } = useTranslation();
    const [scrollPosition, setScrollPosition] = useState(window.scrollY);
    const { showAllRulesPopup, setShowAllRulesPopup } = useContext(GlobalContext);
    const heroRef = useRef();

    const updateScrollPosition = useCallback(() => {
        if (window.scrollY > -1 && heroRef.current) {
            setScrollPosition(window.scrollY);
        }
    }, [])


    useEffect(() => {
        window.addEventListener("scroll", () => updateScrollPosition())
        return () => window.removeEventListener("scroll", () => updateScrollPosition())
    }, [updateScrollPosition])

    return (
        <HomepageHeroWrapper ref={heroRef} scrollPosition={scrollPosition}>
            <Wrapper>
                <header>
                    <BrainsterLogo color={theme.black} />
                    <ButtonBasic variation={"large"} text={t("homepage.rules.header")} onClick={() => setShowAllRulesPopup({ ...showAllRulesPopup, display: true })} />
                </header>
                <div className='vouchers-main-content'>
                    <div className="vouchers-header-container">
                        {vouchers_vou}
                        {vouchers_chers}
                        {voucher_card}
                    </div>
                    <div className="vouchers-description-container">
                        <div>

                            <MonumentHeader24>A Test for the Best!</MonumentHeader24>
                            <Text16 fontWeight={700}>{t("homepage.hero_section.description")}</Text16>
                        </div>
                        {twoPointO}
                    </div>
                </div>
                <div className="cta-arrow-container" onClick={() => scrollChooseAcademyIntoView()}>
                    <Text16>{t("homepage.hero_section.cta")}</Text16>
                    {arrow_down}
                </div>
            </Wrapper>
        </HomepageHeroWrapper>
    )
}

export default withTheme(HomePageHero);