export let assessmentVouchersLogo = <svg width="141" height="12" viewBox="0 0 141 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M93.5527 0V8.21193L101.055 0H104.885V10.9282H102.06V2.73184L94.5721 10.9282H90.7271V0H93.5527Z" fill="#C6C6C6" />
    <path d="M13.6244 7.74381C13.6244 9.58605 12.5416 10.9282 10.0459 10.9282H0V0H9.66906C12.1647 0 12.9341 1.26444 12.9341 2.87262C12.9341 3.63784 12.6197 4.38752 12.07 4.99556C13.0122 5.57336 13.6244 6.47937 13.6244 7.74381ZM2.82213 4.29338H8.66091C9.41464 4.29338 10.105 4.29338 10.105 3.15332C10.105 2.03053 9.41029 2.03053 8.64528 2.03053H2.82213V4.29338ZM10.7953 7.60303C10.7953 6.44828 10.1006 6.44828 9.35125 6.44828H2.82213V8.75864H9.35125C10.1084 8.75864 10.7988 8.75864 10.7988 7.60303H10.7953Z" fill="#C6C6C6" />
    <path d="M25.8828 8.60231H19.3068L18.1293 10.9282H15.0215L20.6875 0H24.5021L30.1837 10.9282H27.0577L25.8828 8.60231ZM24.8312 6.51046L22.603 2.0763L20.3627 6.51046H24.8312Z" fill="#C6C6C6" />
    <path d="M63.6166 2.23263V4.44971H72.4069V6.47937H63.6166V8.69645H72.4069V10.9282H60.791V0H72.4069V2.23263H63.6166Z" fill="#C6C6C6" />
    <path d="M42.3826 0L37.1309 11.0129H34.0882L35.3169 8.44598H33.4951L29.0986 0H32.1561L35.9117 7.204L39.3391 0H42.3826Z" fill="#C6C6C6" />
    <path d="M54.6451 0V5.19507C53.8471 6.10626 51.9532 7.04336 50.1297 7.04336C48.1082 7.04336 47.3206 6.22977 47.3206 4.14137V0H44.4898L44.4585 5.15361C44.4854 8.02364 46.1231 9.47895 49.3212 9.47895C51.8395 9.47895 53.5901 8.57381 54.6408 7.78095V10.9282H57.4525V0H54.6451Z" fill="#C6C6C6" />
    <path d="M83.9711 0H75.3389V10.9282H78.1636V8.00896H83.9711C86.4668 8.00896 88.1783 6.44828 88.1783 4.01268C88.1783 1.57709 86.4668 0 83.9711 0ZM84.9385 5.8532L83.4996 5.07588L83.1966 6.67111L82.1103 5.44813L81.3704 6.90258L80.6262 5.44813L79.5408 6.67111L79.2369 5.07243L77.798 5.84974L78.2678 4.31843L76.7221 3.91077L78.2678 3.50311L77.798 1.97179L79.2369 2.74911L79.5399 1.15129L80.6262 2.38032L81.3669 0.925008L82.1068 2.37945L83.1922 1.15734L83.4961 2.75516L84.935 1.97784L84.4661 3.50916L86.0109 3.91682L84.4652 4.32447L84.9385 5.8532Z" fill="#C6C6C6" />
    <path d="M114.892 8.60827H123.109V10.9338H111V9.53516C111 5.05274 120.482 6.05334 120.482 3.60436C120.482 2.71156 119.912 2.32644 117.518 2.32644C115.125 2.32644 114.027 2.68024 114.027 4.39028H111.248C111.248 0.796977 113.781 0 117.363 0C121.085 0 123.263 0.708526 123.263 3.38876C123.263 7.56345 115.572 6.4698 114.892 8.60827Z" fill="#C6C6C6" />
    <path d="M127.077 8.87256V10.9364H124.436V8.87256H127.077Z" fill="#C6C6C6" />
    <path d="M127.726 5.53012C127.726 1.95616 129.765 0.00195312 134.058 0.00195312C138.336 0.00195312 140.39 1.97366 140.39 5.53012C140.39 9.10408 138.336 11.0905 134.058 11.0905C129.765 11.0905 127.726 9.11882 127.726 5.53012ZM137.61 5.53012C137.61 3.528 136.502 2.32655 134.058 2.32655C131.615 2.32655 130.507 3.51234 130.507 5.53012C130.507 7.56356 131.615 8.76502 134.058 8.76502C136.502 8.76502 137.61 7.5479 137.61 5.53012Z" fill="#C6C6C6" />
</svg>