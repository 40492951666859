export const vouchers_header = <svg width="660" height="70" viewBox="0 0 660 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M85.7032 48.7118C85.7032 60.3003 78.8917 68.7431 63.1931 68.7431H0V0H60.8225C76.5211 0 81.3607 7.95384 81.3607 18.07C81.3607 22.8836 79.3833 27.5994 75.9257 31.4242C81.8523 35.0588 85.7032 40.758 85.7032 48.7118ZM17.7524 27.0072H54.4808C59.222 27.0072 63.5645 27.0072 63.5645 19.8357C63.5645 12.7729 59.1947 12.7729 54.3825 12.7729H17.7524V27.0072ZM67.9071 47.8262C67.9071 40.5624 63.5372 40.5624 58.8233 40.5624H17.7524V55.0955H58.8233C63.5864 55.0955 67.9289 55.0955 67.9289 47.8262H67.9071Z" fill="white" />
    <path d="M162.814 54.1122H121.448L114.041 68.7431H94.4919L130.133 0H154.129L189.869 68.7431H170.205L162.814 54.1122ZM156.199 40.9536L142.183 13.0608L128.09 40.9536H156.199Z" fill="white" />
    <path d="M266.603 0L233.567 69.2755H214.427L222.156 53.1288H210.697L183.041 0H202.274L225.898 45.3162L247.458 0H266.603Z" fill="white" />
    <path d="M588.484 0V51.6565L635.678 0H659.767V68.7431H641.998V17.1844L594.897 68.7431H570.71V0H588.484Z" fill="white" />
    <path d="M400.178 14.0442V27.9906H455.472V40.758H400.178V54.7044H455.472V68.7431H382.403V0H455.472V14.0442H400.178Z" fill="white" />
    <path d="M343.742 0V32.6792C338.722 38.4109 326.809 44.3057 315.338 44.3057C302.622 44.3057 297.667 39.1879 297.667 26.051V0H279.86L279.664 32.4184C279.833 50.4721 290.135 59.6266 310.252 59.6266C326.093 59.6266 337.105 53.9329 343.714 48.9454V68.7431H361.401V0H343.742Z" fill="white" />
    <path d="M528.213 0H473.913V68.7431H491.681V50.3797H528.213C543.912 50.3797 554.678 40.5624 554.678 25.2415C554.678 9.92057 543.912 0 528.213 0ZM534.298 36.8191L525.247 31.9294L523.341 41.9641L516.507 34.271L511.854 43.4201L507.172 34.271L500.345 41.9641L498.433 31.9077L489.382 36.7974L492.337 27.1647L482.614 24.6004L492.337 22.036L489.382 12.4034L498.433 17.2931L500.339 7.24212L507.172 14.9732L511.832 5.81869L516.486 14.9678L523.313 7.28015L525.225 17.3311L534.276 12.4415L531.327 22.0741L541.044 24.6384L531.321 27.2028L534.298 36.8191Z" fill="white" />
</svg>

export const twoPointO = <svg width="179" height="71" viewBox="0 0 179 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.314 53.9598H74.1962V67.7942H2.16162V59.4738C2.16162 32.808 58.5726 38.7605 58.5726 24.1916C58.5726 18.8804 55.1764 16.5893 40.9376 16.5893C26.6989 16.5893 20.1703 18.694 20.1703 28.867H3.63441C3.63441 7.49053 18.703 2.74933 40.0144 2.74933C62.1556 2.74933 75.1139 6.96434 75.1139 22.909C75.1139 47.7441 29.3587 41.238 25.314 53.9598Z" stroke="#F5EB5D" strokeWidth="4" strokeMiterlimit="10" />
    <path d="M97.8048 55.5329V67.8107H82.0933V55.5329H97.8048Z" stroke="#F5EB5D" strokeWidth="4" strokeMiterlimit="10" />
    <path d="M101.663 35.6473C101.663 14.3859 113.791 2.76035 139.334 2.76035C164.784 2.76035 177 14.49 177 35.6473C177 56.9087 164.784 68.7261 139.334 68.7261C113.791 68.7261 101.663 56.9964 101.663 35.6473ZM160.464 35.6473C160.464 23.7367 153.87 16.5893 139.334 16.5893C124.799 16.5893 118.204 23.6436 118.204 35.6473C118.204 47.7442 124.799 54.8916 139.334 54.8916C153.87 54.8916 160.464 47.651 160.464 35.6473Z" stroke="#F5EB5D" strokeWidth="4" strokeMiterlimit="10" />
</svg>

