export const rules_header = <svg width="378" height="60" viewBox="0 0 378 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M115.927 25.6416C115.927 39.8526 105.699 44.8425 93.626 44.8425C85.5584 44.8425 79.0769 42.6948 75.0095 38.4636V60H64.0828V7.01165H75.0095V12.7578C79.0743 8.58836 85.5558 6.37891 93.626 6.37891C105.699 6.37891 115.927 11.4306 115.927 25.6416ZM105.062 25.6416C105.062 18.2518 99.7272 15.4096 90.0062 15.4096C80.2852 15.4096 75.0121 18.2518 75.0121 25.6416C75.0121 32.967 80.2852 35.8092 90.0062 35.8092C99.7272 35.8092 105.062 32.967 105.062 25.6416Z" fill="#EEEEEE" />
    <path d="M169.487 25.4511V44.2096H158.56V39.6004C151.952 42.8207 144.648 44.8424 135.817 44.8424C125.335 44.8424 118.789 41.496 118.789 33.0955C118.789 23.432 129.462 20.402 141.343 20.402C147.76 20.402 153.67 21.4129 158.56 23.0539C158.56 15.9805 149.411 15.4121 144.329 15.4121C139.436 15.4121 133.465 15.8545 125.777 18.1283L121.901 10.2344C129.524 7.7703 137.022 6.38135 144.836 6.38135C160.465 6.37878 169.36 11.3687 169.487 25.4511ZM158.56 29.7465C154.749 28.5454 149.602 27.4728 142.676 27.4728C134.544 27.4728 129.651 28.8617 129.651 32.6505C129.651 36.5653 133.273 36.8816 138.927 36.8816C144.836 36.8816 152.969 35.0503 158.558 32.6505V29.7465H158.56Z" fill="#EEEEEE" />
    <path d="M221.354 33.3683C221.354 39.6391 217.665 44.2098 209.165 44.2098H174.952V7.01172H207.882C216.381 7.01172 219 11.3149 219 16.7909C219 19.3939 217.931 21.9455 216.061 24.0186C219.269 25.9812 221.354 29.0651 221.354 33.3683ZM184.574 21.624H204.407H204.461C207.028 21.624 209.38 21.624 209.38 17.7452C209.38 13.9179 207.028 13.9179 204.409 13.9179H184.577V21.624H184.574ZM211.732 32.8899C211.732 28.9571 209.38 28.9571 206.813 28.9571H184.574V36.8227H206.813C209.38 36.8227 211.732 36.8227 211.732 32.8899Z" fill="#EEEEEE" />
    <path d="M234.131 7.01172V34.9656L259.685 7.01172H272.728V44.2124H263.105V16.31L237.606 44.2098H224.509V7.01172H234.131Z" fill="#EEEEEE" />
    <path d="M308.44 29.1705L300.848 14.0772L285.613 44.2097H275.028L294.328 7.00903H307.319L326.671 44.2097H316.034L312.023 36.2927L308.44 29.1705Z" fill="#EEEEEE" />
    <path d="M378 25.4512V44.2097H367.073V39.6005C360.465 42.8208 353.161 44.8425 344.33 44.8425C333.848 44.8425 327.302 41.4962 327.302 33.0956C327.302 23.4321 337.975 20.4021 349.857 20.4021C356.273 20.4021 362.183 21.413 367.073 23.054C367.073 15.9807 357.924 15.4122 352.843 15.4122C347.95 15.4122 341.978 15.8546 334.291 18.1284L330.415 10.232C338.037 7.76785 345.536 6.37891 353.35 6.37891C368.978 6.37891 377.873 11.3688 378 25.4512ZM367.073 29.7467C363.262 28.5455 358.116 27.4729 351.189 27.4729C343.057 27.4729 338.164 28.8619 338.164 32.6506C338.164 36.5654 341.786 36.8818 347.44 36.8818C353.35 36.8818 361.482 35.0504 367.071 32.6506V29.7467H367.073Z" fill="#EEEEEE" />
    <path d="M47.6501 0H11.4364H0V44.2097H11.4364V9.59918H47.6501V17.3053V26.9044V44.2097H59.0865V0H47.6501Z" fill="#EEEEEE" />
</svg>

export const rules_header_mobile = <svg width="208" height="34" viewBox="0 0 208 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M63.7898 14.5302C63.7898 22.5831 58.1616 25.4108 51.5183 25.4108C47.079 25.4108 43.5125 24.1937 41.2743 21.7961V34H35.2617V3.9733H41.2743V7.22944C43.511 4.86677 47.0776 3.61475 51.5183 3.61475C58.1616 3.61475 63.7898 6.47735 63.7898 14.5302ZM57.8114 14.5302C57.8114 10.3427 54.8756 8.73216 49.5265 8.73216C44.1774 8.73216 41.2757 10.3427 41.2757 14.5302C41.2757 18.6813 44.1774 20.2919 49.5265 20.2919C54.8756 20.2919 57.8114 18.6813 57.8114 14.5302Z" fill="#EEEEEE" />
    <path d="M93.2626 14.4224V25.0522H87.25V22.4403C83.6137 24.2652 79.5944 25.4108 74.7351 25.4108C68.9674 25.4108 65.3652 23.5145 65.3652 18.7542C65.3652 13.2782 71.2383 11.5613 77.7762 11.5613C81.3072 11.5613 84.5591 12.1341 87.25 13.064C87.25 9.05574 82.2155 8.73363 79.4193 8.73363C76.7269 8.73363 73.4409 8.98433 69.2108 10.2728L67.078 5.7996C71.2725 4.40328 75.3985 3.61621 79.6983 3.61621C88.2979 3.61475 93.1928 6.44238 93.2626 14.4224ZM87.25 16.8565C85.1528 16.1758 82.3209 15.568 78.5095 15.568C74.0346 15.568 71.3422 16.3551 71.3422 18.5021C71.3422 20.7204 73.3355 20.8997 76.4464 20.8997C79.6983 20.8997 84.1732 19.8619 87.2486 18.5021V16.8565H87.25Z" fill="#EEEEEE" />
    <path d="M121.803 18.9085C121.803 22.462 119.773 25.0521 115.096 25.0521H96.2695V3.97314H114.39C119.067 3.97314 120.508 6.41161 120.508 9.51471C120.508 10.9897 119.92 12.4356 118.89 13.6104C120.656 14.7225 121.803 16.4701 121.803 18.9085ZM101.565 12.2534H112.478H112.508C113.92 12.2534 115.214 12.2534 115.214 10.0555C115.214 7.88664 113.92 7.88664 112.479 7.88664H101.566V12.2534H101.565ZM116.508 18.6374C116.508 16.4089 115.214 16.4089 113.802 16.4089H101.565V20.866H113.802C115.214 20.866 116.508 20.866 116.508 18.6374Z" fill="#EEEEEE" />
    <path d="M128.834 3.97314V19.8137L142.895 3.97314H150.072V25.0535H144.777V9.24215L130.746 25.0521H123.539V3.97314H128.834Z" fill="#EEEEEE" />
    <path d="M169.723 16.5301L165.546 7.97724L157.163 25.0523H151.338L161.958 3.97192H169.107L179.755 25.0523H173.902L171.695 20.566L169.723 16.5301Z" fill="#EEEEEE" />
    <path d="M207.999 14.4224V25.0522H201.986V22.4403C198.35 24.2652 194.331 25.4108 189.471 25.4108C183.704 25.4108 180.102 23.5145 180.102 18.7542C180.102 13.2782 185.975 11.5612 192.513 11.5612C196.043 11.5612 199.295 12.1341 201.986 13.064C201.986 9.05574 196.952 8.73362 194.156 8.73362C191.463 8.73362 188.177 8.98432 183.947 10.2728L181.814 5.79814C186.009 4.40182 190.135 3.61475 194.435 3.61475C203.034 3.61475 207.929 6.44237 207.999 14.4224ZM201.986 16.8565C199.889 16.1758 197.057 15.568 193.246 15.568C188.771 15.568 186.079 16.3551 186.079 18.502C186.079 20.7204 188.072 20.8997 191.183 20.8997C194.435 20.8997 198.91 19.8619 201.985 18.502V16.8565H201.986Z" fill="#EEEEEE" />
    <path d="M26.2201 0H6.29306H0V25.0522H6.29306V5.43953H26.2201V9.80632V15.2459V25.0522H32.5132V0H26.2201Z" fill="#EEEEEE" />
</svg>

