const theme = {
    white: "#FFFFFF",
    gray: "#C6C6C6",
    veryLightGray: "#EEEEEE",
    lightestGray: "#F9F9F9",
    textGray: "#B2B2B2",
    darkGray: "#8B8B8B",
    altYellow: "#F5EB5D",
    yellow: "#F2E635",
    brightYellow: "#F9F39A",
    black: "#161616",
    lightBlack: "#2D2E34",

    GraphicDesign: "#FF7A45",
    DigitalMarketing: "#E8103F",
    Fullstack: "#80ECEA",
    Frontend: "#80ECEA",
    DataScience: "#4D64EF",
    SoftwareTesting: "#04BF68",
    UXUI: "#183FF3",
    ProductManagement: "#D1F00C",
    HR: "#577A80",
    BusinessIntelligence: "#F2E635",

    active: "#005EFF",
    textError: "#FF003A",

    headerLarge40: 40,
    headerLarge40LineHeight: 46,
    header32: 32,
    header32LineHeight: 37,
    headerMedium28: 28,
    headerMedium28LineHeight: 32,
    headerMedium24: 24,
    headerMedium24LineHeight: 28,
    headerSmall24: 24,
    headerSmall24LineHeight: 28,
    subheader18: 18,
    subheader18LineHeight: 21,
    caption12: 12,
    caption12LineHeight: 14,
    labelCaps12: 12,
    labelCaps12LineHeight: 14,
    text12: 12,
    text12LineHeight: 14,
    text14: 14,
    text14LineHeight: 16,
    text16: 16,
    text16LineHeight: 19,
    text18: 18,
    text18LineHeight: 21,
    buttonText12: 12,
    buttonText12LineHeight: 14,
    buttonText14: 14,
    buttonText14LineHeight: 16,
    buttonText16: 16,
    buttonText16LineHeight: 18
}

export default theme;