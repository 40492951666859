import { forwardRef } from 'react';

//npm imports
import styled, { withTheme } from 'styled-components/macro';
import useArrayLength from '../../Hooks/useArrayLength';

//component imports
import { Caption12 } from '../Text/Text';

const InputFieldWrapper = styled.div`
    width: 100%;
    .label{
        margin-bottom: 11px;
    }
    .error{
        height: 14px;
        margin-top: 8px;
    }
`

const InputContainer = styled.aside`
    width: 100%;
`

const Input = styled.input`
    width: 100%;
    height: 58px;
    padding-left: 24px;
    font-family: 'Ubuntu', sans-serif;
    font-size: ${props => props.theme.text16}px;
    line-height: ${props => props.theme.text16LineHeight}px;
    font-weight: 400;
    color: ${props => props.theme.gray};
    border-radius: 4px;
    border: ${props => props.errors?.length > 0 ? `1.5px solid ${props.theme.textError}` : props.val !== "" ? `1.5px solid ${props.theme.darkGray}` : "none"};
    outline: none;
    &:focus, &:active, &:target{
        outline: none;
    }

    &:hover{
        border: 1.5px solid ${props => props.theme.darkGray};
        color: ${props => props.theme.darkGray}
    }

    &:focus{
        color: ${props => props.theme.black};
        border: 1.5px solid ${props => props.theme.active};
    }
    @media (max-width: 550px){
        padding-left: 12px;
    }

`

const InputField = forwardRef(({
    type, required, placeholder, disabled, autocomplete, borderColor, value, defaultValue, name, onChange, label, errors, theme, className
}, ref) => {
    return (
        <InputFieldWrapper className={className}>
            <Caption12 className="label">{label}<span>{required && "*"}</span></Caption12>
            <InputContainer>
                <Input
                    errors={errors}
                    type={type}
                    ref={ref}
                    placeholder={placeholder}
                    disabled={disabled}
                    name={name && name}
                    onChange={onChange}
                    defaultValue={defaultValue}
                    val={value}
                    borderColor={borderColor}
                    autocomplete={autocomplete}
                />
            </InputContainer>
            <Caption12 className="error" color={theme.textError}>{useArrayLength(errors) && errors.map((error, index) => (<span key={index}><span>{error}</span><br /></span>))}</Caption12>
        </InputFieldWrapper>
    )
})

export default withTheme(InputField);