import { useEffect, useState, useContext } from 'react';

//npm imports
import { useTranslation } from 'react-i18next';
import styled, { keyframes, withTheme } from 'styled-components/macro';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

//component imports
import CountdownTimer from '../../Components/CountdownTimer/CountdownTimer'
import AnswerButton, { StyledAnswerButton } from '../../Components/AnswerButton/AnswerButton';
import ButtonWide, { StyledButtonWide } from '../../Components/ButtonWide/ButtonWide';
import { HeaderMedium24, LabelCaps12, Text14 } from '../../Components/Text/Text';
import BrainsterLogo from '../../Components/BrainsterLogo/BrainsterLogo';

//other imports
import form_background from './Static/assessment-form-clip.svg'
import { GlobalContext } from '../../Context/GlobalContext';
import { hourglass } from './Static/hourglass.js';
import { API } from '../../Consts/Api';
import assessment_page_background from './Static/assessment-page-background.svg';
import { assessmentVouchersLogo } from './Static/assessment-vouchers-logo.js';

const timePassing = (remainingTime) => keyframes`
    from{
        width: ${remainingTime}%;
    }
    to{
        width: 0%;
    }
`

//styled-components
export const AssessmentWrapper = styled.main`
    width:100%;
    min-height: 100vh;
    background-color: ${props => props.theme.lightBlack};
    display: flex;
    justify-content: center;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;    
    box-sizing: border-box; 
    background-image: url(${assessment_page_background});
    background-size: cover;
    background-repeat: no-repeat;
    &>form{
        width: 69%;
        min-height: 100%;
        padding: 85px 6.9% 23px 6.9%;
        background-image: url(${form_background});
        background-size: cover;
        background-repeat: repeat-y;
        background-position: right;
        display: flex;
        flex-direction: column;
        position: relative;
        .countdown-progress{
            width: 100%;
            height: 8px;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: flex-end;
            .countdown-progressbar{
                height: 100%;
                background-color: ${props => props.theme.lightBlack};
                animation: ${props => timePassing((props.duration / 1500) * 100)} ${props => props.duration - 2}s linear forwards;
            }
        }
        h1,h2,h3,h4,h5,h6,h7,p,ol,ul,blockquote{
            font-family: 'Ubuntu', sans-serif;
        }
        em {
            font-style: normal;
        }
        .header{
            width: 100%;
            margin-bottom: 40px;
            display: flex;
            justify-content: space-between;
            .timer{
                display: flex;
                align-items: center;
                svg{
                    margin-left: 14px;
                }
            }
        }
        ${LabelCaps12}{
            margin-bottom: 28px;
        }
        ${HeaderMedium24}{
            margin-bottom: 38px;
            img{
                width: 100%;
                margin-top: 16px;
                margin-bottom: 16px;
            }
            p{
                font-weight: 700;
            }
            &>p:last-of-type{
                font-size: 12px;
            }
        }
        &>.question-container{
            p{
                font-size: 18px;
            }
            &>img{
                max-width: 80%;
                margin-top: 16px;
                margin-bottom: 16px;
            }
            p:first-child{
                font-weight: 700;
            }
            p, ol, ul{
                margin-bottom: 16px;
            }
        }
        
        ${StyledAnswerButton}{
            max-width: 490px;
            margin: 8px 0;
        }
        ${StyledButtonWide}{
            margin-top: 40px;
        }
        .image-answers-container{
            display: flex;
            gap: 16px;
            button{
                min-height: 14vw;
                .button-container{
                    min-height: 14vw;
                    p{ 
                        justify-content: center;
                        img{
                            width: 100%;
                        }
                    }
                    .button-circle{
                        display: none;
                    }
                }
            }
        }
        .brainster-logo-container{
            margin-top: 80px;
            align-self: center;
        }
    }
    @media (max-width: 1100px){
        form{
            width: 78%;
            padding: 65px 4.5% 23px 4.5%;
            .image-answers-container{
                button{
                    .button-container{
                        padding: 12px;
                    }
                }
            }
        }
    }
    @media (max-width: 900px){
        form{
            width: 85%;
        }
    }
    @media (max-width: 786px){
        &>form{
            width: 100%;
            background-image: none;
            background-color: ${props => props.theme.white};
            .header{
                align-items: center;
            }
            ${StyledAnswerButton}{
                max-width: unset;
            }
            &>.question-container > p {
                margin-bottom: 40px;
            }
            &>.question-container > img {
                width: 100%;
                max-width: unset;
            }
        }
    }
    @media (max-width: 550px){
        &>form{

            &>.image-answers-container{
                flex-wrap: wrap;
                button{
                    flex-basis: calc(50% - 8px);
                    margin: 0;
                }
            }
            .brainster-logo-container{
                margin-top: 56px;
            }
        }
    }
`

const Assessment = (props) => {

    const { t } = useTranslation();
    const { user, setUserInfo, allQuestions, duration, currentQuestion, setCurrentQuestion, nextQuestion, onTimeOutNavigateUserToResult } = useContext(GlobalContext);
    const [selectedAnswerId, setSelectedAnswerId] = useState(false);
    const [errors, setErrors] = useState([]);

    let navigate = useNavigate();

    useEffect(() => {
        //if user has no active assessment session, redirect user to homepage
        if (localStorage.getItem("guid") === null) {
            navigate(API.createRoute("home"));
        }
        //eslint-disable-next-line
    }, [])

    const chooseAnswer = (answerId) => {
        if (selectedAnswerId !== answerId) {
            setSelectedAnswerId(answerId)
        }
    }

    const nextAction = (e, questionId, answerId) => {
        e.preventDefault();
        setSelectedAnswerId(false)
        if (questionId === currentQuestion.id && typeof questionId === "number" && answerId && typeof answerId === "number") {
            API.axios.post(API.createApiRoute("assessment"), { question_id: questionId, question_answer_id: answerId })
                .then(response => {
                    if (response.data.success) {
                        //if not last question, change the question to next question
                        if (!response.data.data.finished) {
                            setCurrentQuestion({ ...allQuestions[nextQuestion.index], index: nextQuestion.index })
                        } else {//if last question, navigate to result
                            let guid = localStorage.getItem("guid")
                            localStorage.removeItem("guid");
                            navigate(API.createRoute("result", { GUID: guid }));
                            setUserInfo(user);
                        }
                    }
                })
                .catch(error => setErrors([...errors, ...error.response.data.errors]))
        }
    }

    //set timer to remaining time to navigate user to result page if time runs out
    useEffect(() => {
        if (typeof duration === "number" && duration >= 0) {
            onTimeOutNavigateUserToResult();
        }
        //eslint-disable-next-line
    }, [duration])



    function createMarkup(text) {
        return { __html: text };
    }

    return (
        <AssessmentWrapper duration={duration}>
            <form onSubmit={(e) => nextAction(e, currentQuestion.id, selectedAnswerId)}>
                <div className="countdown-progress">
                    <div className="countdown-progressbar"></div>
                </div>
                <div className="header">
                    {assessmentVouchersLogo}
                    <div className="timer">
                        <CountdownTimer duration={duration ? duration - 2 : duration} />
                        {hourglass}
                    </div>
                </div>

                {
                    currentQuestion?.id &&
                    <>
                        {allQuestions?.length > 0 && currentQuestion.index && <LabelCaps12 color={props.theme.gray}>{t("assessment.question")} {parseFloat(currentQuestion.index) + 1} {t("assessment.from")} {allQuestions.length}</LabelCaps12>}
                        {currentQuestion?.text && <div className="question-container" dangerouslySetInnerHTML={createMarkup(currentQuestion.text)}></div>}
                        {currentQuestion?.answers.length > 0 && currentQuestion.answers[0]?.text?.indexOf("<img ") > -1
                            ?
                            <div className={"image-answers-container"}>
                                {currentQuestion.answers.map((answer, index) => <AnswerButton isImageAnswer={answer.text.indexOf("<img ") > -1} key={index} onClick={(e) => [e.preventDefault(), chooseAnswer(answer.id)]} type="button" text={answer.text} active={selectedAnswerId === answer.id} />)}
                            </div>
                            : currentQuestion?.answers.length > 0 && currentQuestion.answers.map((answer, index) => <AnswerButton isImageAnswer={answer.text.indexOf("<img ") > -1} key={index} onClick={(e) => [e.preventDefault(), chooseAnswer(answer.id)]} type="button" text={answer.text} active={selectedAnswerId === answer.id} />)}
                        <Text14 color={props.theme.textError}>{errors.length > 0 && errors.map(error => <><span>{error}</span><br /></>)}</Text14>
                        <ButtonWide text={t("assessment.next_question")} type={"submit"} disabled={!selectedAnswerId} />
                    </>
                }

                <div className='brainster-logo-container'>
                    <Link to={API.createRoute("home")}>
                        <BrainsterLogo color={props.theme.gray} />
                    </Link>
                </div>
            </form>
        </AssessmentWrapper>
    )
}

export default withTheme(Assessment);