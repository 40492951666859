//npm imports
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components/macro';

//component imports
import { Caption12, HeaderSmall24, Text14, Text18 } from '../Text/Text';
import ButtonBasic from '../ButtonBasic/ButtonBasic';

//other imports
import background from './Static/academy_card_clip.svg';


const StyledAcademyCard = styled.article`
    height: 389px;
    background-image: url(${background});
    background-size: cover;
    background-repeat: repeat-y;
    background-position: right;
    padding: 42px 24px 150px 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .mobile-colored-border{
        display: none;
        background-color: ${props => props.theme[props.borderColor] || props.theme.yellow};
        position: absolute;
        width: 11%;
        height: 4px;
        top: 0;
        left: 0;
    }
    header{

        ${HeaderSmall24}{
            width: 85%;
            max-width: 270px;
            margin-top: 16px;
            margin-bottom: 27px;
        }
    }
    main{
        ${Text18}{
            margin-top: 12px;
            margin-bottom: 19px;
        }
    }
    .card-cta{
        width: 100%;
        margin-left: -24px;
        border-left: 5px solid ${props => props.theme[props.borderColor] || props.theme.yellow};
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 24px;
        position: absolute;
        bottom: 24px;
        a{
            transition: transform 0.1s linear;
            button{
                transition: transform 0.1s linear;
            }
            &:last-child{
                margin-left: 24px;
            }
            &:hover{
                cursor: pointer;
                transform: scale(1.07);
            }
        }
    }
    &:hover{
        button{
            transform: scale(1.05);
        }
    }
    @media (max-width: 1080px){
        header{
            ${HeaderSmall24}{
                font-size: 20px;
                line-height: 24px;
            }
        }
    }

    @media (max-width: 1005px){
        header{
            ${HeaderSmall24}{
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
    @media (max-width: 714px){
        
        header{
            ${HeaderSmall24}{
                font-size: 20px;
                line-height: 24px;
            }
        }
    }


    @media (max-width: 665px){

        header{
            ${HeaderSmall24}{
                font-size: 24px;
                line-height: 28px;
            }
        }
        .card-cta{
            a{
                &:hover{
                    transform: scale(1.05);
                }
            }
        }
        &:hover{

            button{
                transform: scale(1.03);
            }
        }
    }

    @media (max-width: 340px){
        
        header{
            ${HeaderSmall24}{
                font-size: 20px;
                line-height: 24px;
            }
        }
    }

    @media (max-width: 425px){
        height: 411px!important;
        min-height: 381px;
        padding: 24px 16px 166px 16px;
        .mobile-colored-border{
            display: block;
        }
        header{
            ${HeaderSmall24}{
                margin-bottom: 24px;
                margin-top: 12px;
            }
        }
        .card-cta{
            border: none;
            margin-top: 24px;
            margin-left: -16px;
            display: flex;
            flex-direction: column-reverse;
            padding: 0 24px 0 16px;
            a:first-child{
                text-align: right;
            }
            a:last-child{
                width: 100%;
                margin-left: 0px;
                button{
                    width: 100%;
                    margin-bottom: 16px;
                }
            }
        }
    }
`




const AcademyCard = ({ href, moreInfoUrl, title, theme, borderColor, academyDuration, startDate }) => {
    const { t } = useTranslation();
    return (
        <StyledAcademyCard borderColor={borderColor}>
            <div className="mobile-colored-border"></div>
            <header className="card-header">
                <Caption12 color={theme.textGray}>{t("general.voucher_for")}</Caption12>
                <HeaderSmall24>{title}</HeaderSmall24>
            </header>
            <main className="card-info">
                <Caption12 color={theme.textGray}>{t("general.duration")}</Caption12>
                <Text18>{academyDuration}</Text18>
                <Caption12 color={theme.textGray}>{t("general.start_date")}</Caption12>
                <Text18>{startDate}</Text18>
            </main>
            <div className="card-cta">
                <a href={moreInfoUrl} target="_blank" rel="noreferrer">
                    <Text14 fontWeight={700} color={theme.darkGray}>{t("general.find_out_more")}</Text14>
                </a>
                <Link to={href}>
                    <ButtonBasic text={t("general.choose")} variation="large" />
                </Link>
            </div>
        </StyledAcademyCard>
    )
}

export default withTheme(AcademyCard);