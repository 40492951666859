import { useState, useEffect, useRef, useCallback, memo } from 'react';
//npm imports
import styled, { withTheme } from 'styled-components/macro';

//component imports
import { HeaderMedium24, Text18 } from '../Text/Text';

//styled-components
const StyledAccordion = styled.div`
    width: 100%;
    padding: 0px 25px;
    border-top: 2px solid ${props => props.theme.black};
    &:last-child{
        border-bottom: 2px solid ${props => props.theme.black};
    }
    background-color: ${props => props.theme.white};
    button {
        width: 100%;
        padding: 40px 0;
        background-color: transparent;
        border: none;
        header{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: ${props => props.contentMaxHeight ? "25px" : "0px"};
            
            transition: margin-bottom .2s linear;
            h5{
                width: calc(100% - 44px);
                text-align: left;
            }
            .plus-minus-icon-container{
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                align-self: flex-start;
                div{
                    width: 24px;
                    height: 2px;
                    background-color: ${props => props.contentMaxHeight ? props.theme.darkGray : props.theme.black};
                    transition: background-color .2s linear;
                }
                .vertical{
                    position: absolute;
                    z-index: 2;
                    top: 50%;
                    left: 0;
                    transform: rotate(${props => props.contentMaxHeight ? "0deg" : "-90deg"});
                    transition: transform .2s linear;
                }
            }
        }
        &:hover{
            cursor: pointer;
        }
    }
    section{
        width: 100%;
        max-height: ${props => props.contentMaxHeight}px;
        overflow: hidden;
        transition: max-height .2s ease-in-out;
        p{
            text-align: left;
        }
    }
    @media (max-width: 586px){
        padding: 0px;
        button{
            padding: 24px 0;
            header{
                margin-bottom: ${props => props.contentMaxHeight ? "10px" : "0px"};
                h5{
                    font-size: 18px;
                    line-height: 20px;
                }
            }
        }
        section{
            p{
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
`

//main component
const Accordion = memo(({ header, content, propShowMore, theme }) => {
    const [showMore, setShowMore] = useState();
    const [contentMaxHeight, setContentMaxHeight] = useState(0)

    //accordion content refference use to toggle show and hide of the content
    const contentRef = useRef(null);

    //function to toggle show and hide of accordion content
    const toggleAccordion = useCallback(() => {
        setContentMaxHeight(showMore ? contentRef.current.scrollHeight + 20 : 0);
    }, [showMore, contentRef])

    // //check if there is a true value for props.showMore and if so, setShowMore to be true so that this accordion item would be opened upon mount
    useEffect(() => {
        if (propShowMore === true) {
            setShowMore(true);
        } else {
            setShowMore(false);
        }
    }, [propShowMore])

    useEffect(() => {
        toggleAccordion();
    }, [showMore, toggleAccordion])
    return (
        <StyledAccordion contentMaxHeight={contentMaxHeight}>
            <button onClick={(e) => setShowMore(!showMore)}>
                <header>
                    <HeaderMedium24>{header}</HeaderMedium24>
                    <div className="plus-minus-icon-container">
                        <div className="horizontal"></div>
                        <div className="vertical"></div>
                    </div>
                </header>
                <section ref={contentRef}>
                    <Text18 color={theme.darkGray}>
                        {content}
                    </Text18>
                </section>
            </button>
        </StyledAccordion>
    )
})

export default withTheme(Accordion);