
import { useEffect, useState, useRef, useContext } from 'react';

//npm imports
import styled, { withTheme } from 'styled-components/macro';
import { API } from '../../Consts/Api';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

//component imports
import BrainsterLogo from '../../Components/BrainsterLogo/BrainsterLogo';
import { Caption12, Header32, HeaderLarge40, Subheader18, Text12, Text14, Text18 } from '../../Components/Text/Text';

//other imports
import note_clip_path from './Static/note-clip-path.svg';
import media_blog_shapes from './Static/media_blog_shapes.svg';
import brainster_blog_shapes from './Static/brainster_blog_shape.svg';
import brainster_blog_mobile_shapes from './Static/brainster_blog_mobile_shapes.svg';
import result_page_background from './Static/result_background.svg';
import result_card_shape from './Static/result_card_shape.svg';
import brainster_blog_background from './Static/brainster_blog_background.svg'
import { GlobalContext } from '../../Context/GlobalContext';

//styled components
const ResultWrapper = styled.main`
    min-height: 100vh;
    background-color: ${props => props.theme.lightBlack};
    display: flex;
    justify-content: center;
    padding: 60px 0px 120px 0;
    background-image: url(${result_page_background});
    background-size: 190%;
    background-position: center;
    &>section{
        width: 63%;
        max-width: 912px;
        height: max-content;
        min-height: 500px;
        background-image: url('${note_clip_path}');
        background-size: cover;
        background-repeat: repeat-y;
        background-position: left;
        padding: 40px 0 0 0;
        .brainster-logo-container{
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 42px;
        }

        ${Header32}, ${Subheader18}{
            text-align: center;
        }

        ${Header32}{
            width: 47%;
            margin: 0 auto 16px auto;   
        }

        ${Subheader18}{
            margin-bottom: 40px;
        }

        .result-content-container{
            width: 63%;
            min-width: 515px;
            margin: 0 auto;
            .result-code-container{
                width: 100%;
                background-image: url(${result_card_shape});
                background-size: cover;
                background-repeat: repeat-y;
                padding-top: 115px;
                .result-code-content{
                    width: 100%;
                    border-top: 2px solid ${props => props.theme.white};
                    padding: 24px 24px 12px 32px;
                    ${Caption12}{
                        margin-bottom: 20px;
                    }
                    ${HeaderLarge40}{
                        margin-bottom: 16px;
                        text-decoration: underline;
                    }
                    ${Text14}{
                        margin-bottom: 12px;
                    }
                }
            }
            .refer-friend-container{
                margin-top: 60px;
                padding-bottom: 47px;
                border-bottom: 1px solid ${props => props.theme.veryLightGray};
                &>${Text14}{
                    margin-bottom: 20px;
                }
                .copy-link-input-containert{
                    width: 100%;
                    height: 64px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: ${props => props.theme.lightBlack};
                    border-radius: 4px;
                    padding: 0px 24px;
                    margin-bottom: 8px;
                    &>div{
                        width: 85%;
                        display: flex;
                        align-items: center;
                        svg{
                            width: 24px;
                            margin-right: 24px;
                        }
                        input{
                            font-family: 'Ubuntu', sans-serif;  
                            color: ${props => props.theme.white};
                            width: calc(100% - 48px);
                            background: unset;
                            border: none;
                            font-weight: 500;
                            &:focus, &:active, &:visited {
                                outline: none;
                                box-shadow: none;
                                border: none;
                            }
                        }
                    }
                    ${Text14}{
                        color: ${props => props.theme.yellow};
                        &:hover{
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .cta-container{
            width: 63%;
            min-width: 515px;
            margin: 56px auto 120px auto;
            .blogs-container{
                width: 100%;
                display: flex;
                gap: 9px;
                margin-top: 26px;
                &>a{
                    width: 50%;
                }
            }
        }
    }
    @media (max-width: 1100px){
        &>section{
            width: 74%;
            ${Header32}{
                width: 66%;
            }
        }
    }
    @media (max-width: 950px){
        &>section{
            width: 78%;
        }
    }
    @media (max-width: 768px){
        padding: 30px 0 60px 0;
        &>section{
            width: 90%;
            .result-content-container, .cta-container{
                width: 85%;
                min-width: 420px;
            }
            ${Header32}{
                width: 85%;
                margin: 0 auto 16px auto;   
            }
            
            ${Subheader18}{
                margin-bottom: 40px;
            }
        }
    }
    @media (max-width: 550px){
        background-image: none;
        padding: 0px;
        &>section{
            width: 100%;
            padding-top: 0px;
            background-image: none;
            background-color: white;
            .brainster-header-container{
                padding-top: 59px;
                padding-bottom: 73px;
                background-color: ${props => props.theme.lightBlack};
                display: flex;
                flex-direction: column;
                align-items: center;
                .brainster-logo-container{
                    margin-bottom: 32px;
                    svg{
                        path{
                            fill: ${props => props.theme.white};
                        }
                    }
                }
                ${Header32}{
                    font-size: 32px;
                    line-height: 36px;
                    width: 90%;
                    margin: 0 auto 11px auto;
                }
                ${Subheader18}{
                    width: 60%;
                    margin-bottom: 0;
                }
                ${Header32}, ${Subheader18}{
                    color: ${props => props.theme.white};
                }
            }
            .result-content-container{
                width: 90%;
                min-width: 290px;
                margin-top: -41px;
                .refer-friend-container{
                    padding-bottom: 42px;
                    border-bottom: unset;
                    &>${Text14}{
                        margin-bottom: 14px;
                    }
                    .copy-link-input-containert{
                        padding: 0 18px;
                        &>div{
                            svg{
                                margin-right: 18px;
                            }
                        }
                    }
                }
                .refer-friend-container{
                    margin-top: 72px;
                }
            }
            .cta-container{
                width: 90%;
                min-width: 290px;
                margin: 0 auto;
                padding: 46px 0px 56px 0px;
                border-top: 1px solid ${props => props.theme.veryLightGray};
                .blogs-container{
                    width: 100%;
                    margin: 45px 0 0 0;
                    flex-direction: column;
                    a{
                        width: 100%;
                    }
                }
                }
        }
    }
    @media (max-width: 350px){

        .result-code-container{
            background-size: contain;
            background-position: right;
        }
    }
`

const BlogCta = styled.div`
    height: 100px;
    border-radius: 10px;
    padding: 18px 0 0 14px;
    position: relative;
    background-image: url(${props => props.background && props.background});
    background-size: cover;
    background-repeat: no-repeat;
    img{
        position: absolute;
    }
    .block-mobile-shape {
        display: none;
    }
    &.brainster_blog{
        background-color: ${props => props.theme.altYellow};
        img{
            top: -10px;
            right: 5px;
        }
    }
    &.media_blog{
        background-color: ${props => props.theme.lightBlack};
        img{
            bottom: -15px;
            right: 0px;
        }
    }
    @media (max-width: 550px){
        height: 130px;
        .block-mobile-shape{
            display: block;
        }
        .media_blog{

            &.block-shape{
                display: none;
            }
        }
    }
`

//main component
const Result = (props) => {
    const { t } = useTranslation();
    const { formatResultsDate, setDuration } = useContext(GlobalContext)
    const [result, setResult] = useState(false);
    const referFriendInputRef = useRef();
    const [isLinkCopied, setIsLinkCopied] = useState(false);

    const ctaBlogs = [
        {
            id: 1,
            link: "https://blog.brainster.co/",
            title: t("result.cta.brainster_blog"),
            color: props.theme.black,
            class: "brainster_blog",
            shape: brainster_blog_shapes,
            mobile_shape: brainster_blog_mobile_shapes,
            background: brainster_blog_background
        },
        {
            id: 2,
            link: "https://media.next.edu.mk/",
            title: t("result.cta.media_blog"),
            color: props.theme.white,
            class: "media_blog",
            shape: media_blog_shapes,
        },


    ]
    let params = useParams();
    useEffect(() => {
        API.axios.get(API.createApiRoute("result", { GUID: params.guid }))
            .then(response => response.data.success && setResult(response.data.data))
            .catch(error => console.error(error.response))
        setDuration(false)
        //eslint-disable-next-line
    }, [])

    const copyLinkToClipboard = (e) => {
        referFriendInputRef.current.select();
        document.execCommand('copy');
        referFriendInputRef.current.blur();
        setIsLinkCopied(true)
    }

    return (
        <ResultWrapper>
            <section>
                <div className="brainster-header-container">

                    <div className='brainster-logo-container'>
                        <Link to={API.createRoute("home")}>
                            <BrainsterLogo color={props.theme.gray} />
                        </Link>
                    </div>
                    {result && <Header32>{result.completed ? t("result.main.completed.header") : t("result.main.failed.header")}</Header32>}
                    {result && <Subheader18>{result.completed ? t("result.main.completed.description").replace("{PERCENT}", result.score) : t("result.main.failed.description")}</Subheader18>}
                </div>
                <div className="result-content-container">

                    {result && result.completed &&
                        <div className="result-code-container">
                            <div className="result-code-content">
                                <Caption12>{t("result.main.completed.your_code")}</Caption12>
                                <HeaderLarge40>{result.token}</HeaderLarge40>
                                <Text14 fontWeight={700} >{t("result.main.completed.winners_date").replace("{EXPIRATION_DATE}", () => formatResultsDate()).replace("{DOMAIN}", process.env.REACT_APP_DOMAIN_URL)}</Text14>
                                <Text14 fontWeight={700} >{t("result.main.completed.results_in_email")}</Text14>
                            </div>
                        </div>
                    }
                    {result &&
                        <>
                            <div className="refer-friend-container">
                                <Text14 fontWeight={700}>{t("result.refer_a_friend.header")}</Text14>
                                <div className="copy-link-input-containert">
                                    <div>

                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17 7H13V9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H13V17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7ZM11 15H7C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9H11V7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H11V15ZM8 11H16V13H8V11Z" fill="#8B8B8B" />
                                        </svg>
                                        <input ref={referFriendInputRef} type="text" readOnly={true} value={`${process.env.REACT_APP_DOMAIN_URL}reffer/${params.guid}`} />
                                    </div>
                                    <Text14 fontWeight={700} onClick={(e) => copyLinkToClipboard(e)}>{!isLinkCopied ? t("general.copy") : t("general.copied")}</Text14>
                                </div>
                                <Text12>{t("result.refer_a_friend.description")}</Text12>
                            </div>
                        </>
                    }
                </div>
                <div className="cta-container">
                    <Text14 fontWeight={700}>{t("result.cta.header")}</Text14>
                    <div className="blogs-container">
                        {ctaBlogs.length > 0 && ctaBlogs.map(blog => (
                            <a key={blog.id} href={blog.link} target="_blank" rel="noreferrer">
                                <BlogCta className={`${blog.class} blog`} background={blog.background}>
                                    <Text18 fontWeight={700} color={blog.color}>{blog.title}</Text18>
                                    <img src={blog.shape} className='block-shape' alt="shape" />
                                    {blog.mobile_shape
                                        && <img src={blog.mobile_shape} className='block-mobile-shape' alt="shape" />
                                    }
                                </BlogCta>
                            </a>
                        ))}
                    </div>
                </div>
            </section>
        </ResultWrapper>
    )
}

export default withTheme(Result);