import styled from 'styled-components/macro';


export const MonumentHeader24 = styled.h3`
    font-family: 'MonumentExtended';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: ${props => props.theme.black};
`

//headers
export const HeaderLarge40 = styled.h4`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: ${props => props.fontWeight ? props.fontWeight : 700};
    font-size: ${props => props.theme.headerLarge40}px;
    line-height: ${props => props.theme.headerLarge40LineHeight}px;
    color: ${props => props.color ? props.color : props.theme.black};
`

export const Header32 = styled.h4`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: ${props => props.fontWeight ? props.fontWeight : 700};
    font-size: ${props => props.theme.header32}px;
    line-height: ${props => props.theme.header32LineHeight}px;
    color: ${props => props.color ? props.color : props.theme.black};
`

export const HeaderMedium28 = styled.h4`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: ${props => props.fontWeight ? props.fontWeight : 500};
    font-size: ${props => props.theme.headerMedium28}px;
    line-height: ${props => props.theme.headerMedium28LineHeight}px;
    color: ${props => props.color ? props.color : props.theme.black};
`

export const HeaderMedium24 = styled.h5`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: ${props => props.fontWeight ? props.fontWeight : 500};
    font-size: ${props => props.theme.headerMedium24}px;
    line-height: ${props => props.theme.headerMedium24LineHeight}px;
    color: ${props => props.color ? props.color : props.theme.black};
`

export const HeaderSmall24 = styled.h5`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: ${props => props.fontWeight ? props.fontWeight : 700};
    font-size: ${props => props.theme.headerSmall24}px;
    line-height: ${props => props.theme.headerSmall24LineHeight}px;
    color: ${props => props.color ? props.color : props.theme.black};
`

//subheaders
export const Subheader18 = styled.h5`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: ${props => props.fontWeight ? props.fontWeight : 700};
    font-size: ${props => props.theme.subheader18}px;
    line-height: ${props => props.theme.subheader18LineHeight}px;
    color: ${props => props.color ? props.color : props.theme.black};
`

//captions
export const Caption12 = styled.p`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: ${props => props.fontWeight ? props.fontWeight : 700};
    font-size: ${props => props.theme.caption12}px;
    line-height: ${props => props.theme.caption12LineHeight}px;
    color: ${props => props.color ? props.color : props.theme.black};
`

export const LabelCaps12 = styled.p`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: ${props => props.fontWeight ? props.fontWeight : 700};
    font-size: ${props => props.theme.labelCaps12}px;
    line-height: ${props => props.theme.labelCaps12LineHeight}px;
    color: ${props => props.color ? props.color : props.theme.black};
    text-transform: uppercase;
`

//Text
export const Text18 = styled.p`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
    font-size: ${props => props.theme.text18}px;
    line-height: ${props => props.lineHeight ? props.lineHeight : props.theme.text18LineHeight}px;
    color: ${props => props.color ? props.color : props.theme.black};
`

export const Text16 = styled.p`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
    font-size: ${props => props.theme.text16}px;
    line-height: ${props => props.lineHeight ? props.lineHeight : props.theme.text16LineHeight}px;
    color: ${props => props.color ? props.color : props.theme.black};
`

export const Text14 = styled.p`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
    font-size: ${props => props.theme.text14}px;
    line-height: ${props => props.theme.text14LineHeight}px;
    color: ${props => props.color ? props.color : props.theme.black};
`

export const Text12 = styled.p`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
    font-size: ${props => props.theme.text12}px;
    line-height: ${props => props.theme.text12LineHeight}px;
    color: ${props => props.color ? props.color : props.theme.black};
`

//Button Text
export const ButtonText12 = styled.p`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: ${props => props.fontWeight ? props.fontWeight : 700};
    font-size: ${props => props.buttonText12}px;
    line-height: ${props => props.buttonText12LineHeight}px;
    color: ${props => props.color ? props.color : props.theme.white};
`

export const ButtonText14 = styled.p`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: ${props => props.fontWeight ? props.fontWeight : 700};
    font-size: ${props => props.buttonText14}px;
    line-height: ${props => props.buttonText14LineHeight}px;
    color: ${props => props.color ? props.color : props.theme.white};
`

export const ButtonText16 = styled.p`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: ${props => props.fontWeight ? props.fontWeight : 700};
    font-size: ${props => props.buttonText16}px;
    line-height: ${props => props.buttonText16LineHeight}px;
    color: ${props => props.color ? props.color : props.theme.white};
`