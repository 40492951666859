import { useContext, useEffect, useRef } from 'react';
//npm imports
import { useParams } from 'react-router-dom';

//component imports
import HomePageHero from './Blocks/HomepageHero/HomepageHero';
import ChooseAcademy from './Blocks/ChooseAcademy/ChooseAcademy';
import Rules from './Blocks/Rules/Rules';
import Faq from './Blocks/FAQ/FAQ';
import Footer from '../../Components/Footer/Footer';

//other imports
import { GlobalContext } from '../../Context/GlobalContext';


const Homepage = () => {
    const { userInfo, setUserInfo } = useContext(GlobalContext);
    const chooseAcademyRef = useRef()
    let params = useParams();
    useEffect(() => {
        if (params.referralGuid) {
            setUserInfo({ ...userInfo, referred_by: params.referralGuid })
        }
        //eslint-disable-next-line
    }, [params.referralGuid])
    const scrollChooseAcademyIntoView = () => {
        if (chooseAcademyRef.current) {
            chooseAcademyRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }
    return (
        <>
            <HomePageHero scrollChooseAcademyIntoView={scrollChooseAcademyIntoView} />
            <main className="homepage-main">
                <ChooseAcademy ref={chooseAcademyRef} />
                <Rules />
                <Faq />
            </main>
            <Footer />
        </>
    )
}

export default Homepage;