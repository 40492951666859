import React, { useContext, useEffect, useState, useRef, useMemo, useCallback } from 'react';

//npm imports
import styled, { withTheme } from 'styled-components/macro';
import { useParams, useNavigate, Link } from 'react-router-dom';

//component imports
import InputField from '../../Components/InputField/InputField';
import SelectInput from '../../Components/SelectInput/SelectInput';
import { Caption12, HeaderMedium28, Text16 } from '../../Components/Text/Text';
import ButtonWide, { StyledButtonWide } from '../../Components/ButtonWide/ButtonWide';
import Checkbox from '../../Components/Checkbox/Checkbox';
import BrainsterLogo from '../../Components/BrainsterLogo/BrainsterLogo';

//hooks
import useArrayLength from '../../Hooks/useArrayLength';

//other imports
import { GlobalContext } from '../../Context/GlobalContext';
import { API } from '../../Consts/Api';
import { useTranslation } from 'react-i18next';
import background_image from './Static/aplication_form_background.svg'
import ArrowBack from '../../Components/ArrowBack/ArrowBack';


const ApplicationFormWrapper = styled.section`
    display: flex;
    min-height: 100vh;
    position: relative;
    &>aside{
        width: 42.4%;
        position: relative;
        background-color: ${props => props.theme.lightBlack};
        background-image: url(${background_image});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        &>.logo-and-arrow-container{
            
            &>.brainster-logo{
                margin-left: 15%;
                margin-top: 60px;
            }
            .arrow-back{
                display: none;
            }
        }
        .header-container{
            width: 60%;
            position: absolute;
            top: 50%;
            margin-left: 15%;
            transform: translateY(-50%);
            svg{
                width: 100%;
            }
        }
        .header-container-mobile{
            display: none;
            ${HeaderMedium28}{
                width: 86%;
                color: ${props => props.theme.white};
                font-size: 18px;
                line-height: 20px;
            }
            ${Text16}{
                color: ${props => props.theme.white};
                margin-top: 12px;
            }
        }
    }
    &>main{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 57.6%;
        background-color: ${props => props.theme.veryLightGray};
        z-index: 3;
    }
    @media (max-width: 825px){
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: ${props => props.theme.veryLightGray};
        &>aside{
            width: 100%;
            height: 215px;
            background-color: ${props => props.theme.lightBlack};
            background-image: unset;
            .logo-and-arrow-container, .header-container-mobile{
                width: 82%;
                margin: 32px auto;
            }
            &>.logo-and-arrow-container{
                display: flex;
                justify-content: space-between;
                .arrow-back{
                    display: block;
                    &:hover{
                        cursor: pointer;
                    }
                }
                &>.brainster-logo{
                    margin: 0;
                }
            }
            .header-container{
                display: none;
            }
            .header-container-mobile{
                width: 82%;
                display: block;
                ${HeaderMedium28}{
                }
            }
        }
        &>main{
            width: 100%;
            min-height: 75vh;
            border-top-right-radius: 40px;
            margin-top: -40px;
            form{
                ${HeaderMedium28}{
                    display: none;
                }
            }
        }
    }
    @media (max-width: 550px){
        &>aside{
            .header-container-mobile, .logo-and-arrow-container{
                width: 92%;
            }
        }
        &>main{
            align-items: flex-start;
        }
    }
`

const StyledApplicationForm = styled.form`
    padding-top: 35px;
    width: 71.4%;
    ${HeaderMedium28}{
        width: 82%;
        margin-bottom: 60px;
    }
    .birth-date-container{
        .birthDateInputsContainer{
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 16px;
        }
    }
    .name-inputs-container, .email-and-phone-container{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
    }
    .name-inputs-container, .birth-date-container{
        margin-bottom: 24px;
    }
    
    .email-and-phone-container {
        margin-bottom: 36px;
    }
    ${Caption12}.error{
        color: ${props => props.theme.textError};
        height: 14px;
        margin-top: 8px;
    }
    &>${Caption12}{
        margin-bottom: 16px;
    }
    ${StyledButtonWide}{
        margin-top: 32px;
        margin-bottom: 70px;
    }
    @media (max-width: 1250px){
        width: 78%;
    }

    @media (max-width: 1250px){
        width: 82%;
    }

    @media (max-width: 1015px){
        ${HeaderMedium28}{
            width: 100%;
        }
    }

    @media (max-width: 550px){
        width: 92%;
        .birth-date-container{
            .birthDateInputsContainer{
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 16px;
                .select-input__single-value{
                    padding-left: 4px;
                }
                .select-input__value-container{
                    padding: 2px 4px;
                }
                .select-input__indicators{
                    margin-right: 8px;
                    .select-input__indicator{
                        padding: 0;
                    }
                    svg{
                        transform: scale(0.99);
                    }
                }
            }
        }
        .name-inputs-container, .email-and-phone-container{
            grid-template-columns: 1fr;
            gap: 0px;
        }
        .name-inputs-container, .birth-date-container{
            margin-bottom: 0px;
            .css-1hb7zxy-IndicatorsContainer{
                width: 25px;
            }
        }
        .email-and-phone-container {
            margin-bottom: 16px;
        }
        .error{
            
        }
        ${StyledButtonWide}{
            margin-top: 24px;
            margin-bottom: 40px;
        }
    }
`

const ApplicationForm = (props) => {
    const { t } = useTranslation();
    const { userInfo, setUserInfo, termsAgreement, setTermsAgreement, showAllRulesPopup, setShowAllRulesPopup } = useContext(GlobalContext);
    //The birthDate is handled manually becuase it consists of 3 different fields and the 
    //element #birthDateError displays the errors if there are any
    const resetErrors = { name: [], surname: [], email: [], birthDate: [], academyId: [], no_term: [], limit_exceeded: [] };
    const [errors, setErrors] = useState(resetErrors);

    const nameInputRef = useRef();
    const surnameInputRef = useRef();
    const emailInputRef = useRef();
    const phoneInputRef = useRef();

    //needed for select inputs as options and for updating userInfo.birthDate
    const [days, setDays] = useState([{ value: null, label: t("application_form.form.birth_date.day") }]);
    const [selectedDay, setSelectedDay] = useState(days[0]);
    const [months, setMonths] = useState([{ value: null, label: t("application_form.form.birth_date.month") }]);
    const [selectedMonth, setSelectedMonth] = useState(months[0]);
    const [years, setYears] = useState([{ value: null, label: t("application_form.form.birth_date.year") }]);
    const [selectedYear, SetSelectedYear] = useState(years[0]);

    const formRef = useRef();

    let params = useParams();
    let navigate = useNavigate();

    const allMonths = useMemo(() => [
        t("application_form.form.birth_date.all_months.jan"),
        t("application_form.form.birth_date.all_months.feb"),
        t("application_form.form.birth_date.all_months.mar"),
        t("application_form.form.birth_date.all_months.apr"),
        t("application_form.form.birth_date.all_months.may"),
        t("application_form.form.birth_date.all_months.jun"),
        t("application_form.form.birth_date.all_months.jul"),
        t("application_form.form.birth_date.all_months.aug"),
        t("application_form.form.birth_date.all_months.sep"),
        t("application_form.form.birth_date.all_months.oct"),
        t("application_form.form.birth_date.all_months.nov"),
        t("application_form.form.birth_date.all_months.dec"),
    ], [t]);
    useEffect(() => {
        let days = [{ value: null, label: t("application_form.form.birth_date.day") }];
        for (let i = 0; i < 31; i++) {
            days.push({ value: i + 1, label: i + 1 });
        }
        let mnths = [{ value: null, label: t("application_form.form.birth_date.month") }];
        allMonths.forEach((month, index) => {
            mnths.push({ value: index + 1, label: month })
        })
        let year = new Date();
        let years = [{ value: null, label: t("application_form.form.birth_date.year") }];
        for (let i = year.getFullYear() - 13; i > 1940; i--) {
            years.push({ value: i, label: i });
        }
        setDays(days);
        setMonths(mnths);
        setYears(years);
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        setUserInfo({ ...userInfo, birthDate: { ...userInfo.birthDate, day: selectedDay.value } })
        //eslint-disable-next-line
    }, [selectedDay.value, setUserInfo])
    useEffect(() => {
        setUserInfo({ ...userInfo, birthDate: { ...userInfo.birthDate, month: selectedMonth.value } })
        //eslint-disable-next-line
    }, [selectedMonth.value, setUserInfo])
    useEffect(() => {
        setUserInfo({ ...userInfo, birthDate: { ...userInfo.birthDate, year: selectedYear.value } })
        //eslint-disable-next-line
    }, [selectedYear.value, setUserInfo])

    const applyStudent = async (e, userInfo, academyId) => {
        e.preventDefault();
        if (termsAgreement) {
            let user = {
                name: userInfo.name,
                surname: userInfo.surname,
                email: userInfo.email,
                birth_date: userInfo.birthDate,
                phone: userInfo.phone,
                referred_by: params.referralGuid || userInfo.referred_by || null
            }
            API.axios.post(API.createApiRoute("user"), user)
                .then(response => {
                    if (response.data.success) {
                        if (response.data.data.guid) {
                            setUserInfo({ ...userInfo, guid: response.data.data.guid, academy_id: null });
                            localStorage.setItem("guid", response.data.data.guid);
                            navigate(API.createRoute("confirm", { ACADEMY_ID: academyId }));
                        }
                    } else {
                        setErrors({ ...errors, no_term: [response.data.message] })
                    }
                })
                .catch(error => {
                    if (error?.response?.status === 422) {
                        setErrors({ ...resetErrors, ...error.response.data.errors, limit_exceeded: error.response.data.errors.additional || [], birthDate: [error.response.data.errors['birth_date.day'] || error.response.data.errors['birth_date.month'] || error.response.data.errors['birth_date.year']] });
                    } else {
                        setErrors({ ...errors, ...error.response.data.errors.additional });
                    }
                })
        } else {
            setErrors({ ...errors, terms: t("errors.terms") })
        }
    }

    useEffect(() => {
        errors.terms && termsAgreement && setErrors({ ...errors, terms: false });
        //eslint-disable-next-line
    }, [errors.terms, termsAgreement])

    useEffect(() => {
        !userInfo.academyId && params.academyId && setUserInfo({ ...userInfo, academyId: params.academyId });
        //eslint-disable-next-line
    }, [params, userInfo.academyId, setUserInfo])


    //if there is data about the user in backend already
    //repopulate inputs with that data
    useEffect(() => {
        if (userInfo.name !== "" && userInfo.name !== undefined && nameInputRef.current) {
            nameInputRef.current.value = userInfo.name
        }
        if (userInfo.surname !== "" && userInfo.surname !== undefined && nameInputRef.current) {
            surnameInputRef.current.value = userInfo.surname
        }
        if (userInfo.email !== "" && userInfo.email !== undefined && nameInputRef.current) {
            emailInputRef.current.value = userInfo.email
        }
        if (userInfo.birthDate?.day) {
            let days = [{ value: null, label: t("application_form.form.birth_date.day") }];
            for (let i = 0; i < 31; i++) {
                days.push({ value: i + 1, label: i + 1 });
            }
            days.forEach(day => day.value === userInfo.birthDate.day && setSelectedDay(day))
        }
        if (userInfo.birthDate?.day) {
            let mnths = [{ value: null, label: t("application_form.form.birth_date.month") }];
            allMonths.forEach((month, index) => {
                mnths.push({ value: index + 1, label: month })
            })

            mnths.forEach(month => month.value === userInfo.birthDate.month && setSelectedMonth(month))
        }
        if (userInfo.birthDate?.year) {

            let year = new Date();
            let years = [{ value: null, label: t("application_form.form.birth_date.year") }];
            for (let i = year.getFullYear() - 13; i > 1940; i--) {
                years.push({ value: i, label: i });
            }

            years.forEach(year => year.value === userInfo.birthDate.year && SetSelectedYear(year))
        }

        if (userInfo.phone !== "" && userInfo.phone !== undefined && phoneInputRef.current) {
            phoneInputRef.current.value = userInfo.phone
        }
    }, [
        userInfo.name,
        userInfo.surname,
        userInfo.email,
        userInfo.phone,
        userInfo.birthDate?.day,
        userInfo.birthDate?.month,
        userInfo.birthDate?.year,
        userInfo.academyId,
        setSelectedDay,
        SetSelectedYear,
        setSelectedMonth,
        allMonths,
        t
    ])

    //if there is an error on the users name input and user makes a change in name input, remove the error
    useEffect(() => {
        errors.name.length > 0 && setErrors({ ...errors, name: [] });
        //eslint-disable-next-line
    }, [userInfo.name])
    //if there is an error on the users surname input and user makes a change in surname input, remove the error
    useEffect(() => {
        errors.surname.length > 0 && setErrors({ ...errors, surname: [] });
        //eslint-disable-next-line
    }, [userInfo.surname])
    //if there is an error on the users email input and user makes a change in email input, remove the error
    useEffect(() => {
        errors.email.length > 0 && setErrors({ ...errors, email: [] });
        //eslint-disable-next-line
    }, [userInfo.email])

    useEffect(() => {
        errors.email.length > 0 && setErrors({ ...errors, email: [] });
        //eslint-disable-next-line
    }, [userInfo.email])


    const submitOnEnter = useCallback((e) => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
            applyStudent(e, userInfo, params.academyId);
        }
        //eslint-disable-next-line
    }, [applyStudent, params.academyId])

    useEffect(() => {
        window.addEventListener('keydown', submitOnEnter);

        return () => {
            window.removeEventListener('keydown', submitOnEnter);
        };
    }, [submitOnEnter]);


    return (
        <ApplicationFormWrapper>
            <aside>
                <div className="logo-and-arrow-container">
                    <Link to={API.createRoute("home")}>
                        <ArrowBack color={props.theme.white} />
                    </Link>
                    <BrainsterLogo color={props.theme.white} className="brainster-logo" />
                </div>
                <div className="header-container">
                    <svg width="367" height="30" viewBox="0 0 367 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M248.142 0V21.7816L268.042 0L278.2 0V28.9865H270.707V7.24604L250.846 28.9865H240.647V0L248.142 0Z" fill="white" />
                        <path d="M36.1379 20.5415C36.1379 25.4279 33.2657 28.9879 26.6462 28.9879L0 28.9879L0 0.00146484L25.6466 0.00146484C32.2661 0.00146484 34.3068 3.35531 34.3068 7.62092C34.3068 9.65063 33.473 11.6391 32.0151 13.2519C34.5141 14.7845 36.1379 17.1876 36.1379 20.5415ZM7.48554 11.3894L22.9726 11.3894C24.9718 11.3894 26.8028 11.3894 26.8028 8.36545C26.8028 5.38731 24.9603 5.38731 22.9311 5.38731L7.48554 5.38731L7.48554 11.3894ZM28.6339 20.168C28.6339 17.1051 26.7913 17.1051 24.8036 17.1051L7.48554 17.1051V23.2332L24.8036 23.2332C26.8121 23.2332 28.6431 23.2332 28.6431 20.168H28.6339Z" fill="white" />
                        <path d="M68.6508 22.8171L51.2083 22.8171L48.0851 28.9865H39.8418L54.8705 0L64.9886 0L80.0587 28.9865L71.7671 28.9865L68.6508 22.8171ZM65.8615 17.2686L59.9514 5.50727L54.009 17.2686H65.8615Z" fill="white" />
                        <path d="M168.739 5.92191V11.8026L192.055 11.8026V17.1862L168.739 17.1862V23.0668L192.055 23.0668V28.9865L161.244 28.9865V0L192.055 0V5.92191L168.739 5.92191Z" fill="white" />
                        <path d="M112.416 0L98.4857 29.211L90.4151 29.211L93.6742 22.4025L88.842 22.4025L77.1807 0L85.2904 0L95.2519 19.1082L104.343 0L112.416 0Z" fill="white" />
                        <path d="M144.942 0V13.7796C142.825 16.1965 137.802 18.6821 132.965 18.6821C127.603 18.6821 125.514 16.5241 125.514 10.9848V0L118.005 0L117.922 13.6697C117.994 21.2822 122.338 25.1424 130.821 25.1424C137.5 25.1424 142.143 22.7415 144.93 20.6385V28.9865H152.388V0L144.942 0Z" fill="white" />
                        <path d="M222.726 0L199.83 0V28.9865H207.322V21.2433L222.726 21.2433C229.346 21.2433 233.885 17.1037 233.885 10.6434C233.885 4.18314 229.346 0 222.726 0ZM225.292 15.5253L221.475 13.4635L220.672 17.6947L217.79 14.4508L215.828 18.3087L213.854 14.4508L210.975 17.6947L210.169 13.4543L206.352 15.5161L207.598 11.4544L203.499 10.3731L207.598 9.29179L206.352 5.23007L210.169 7.29186L210.973 3.05374L213.854 6.31365L215.819 2.45353L217.781 6.31136L220.66 3.06977L221.466 7.30789L225.283 5.24611L224.039 9.30783L228.136 10.3891L224.037 11.4704L225.292 15.5253Z" fill="white" />
                        <path d="M299.858 22.5005L321.336 22.5005V28.579L289.686 28.579V24.9232C289.686 13.207 314.471 15.8224 314.471 9.42116C314.471 7.08755 312.979 6.08089 306.723 6.08089C300.467 6.08089 297.598 7.00567 297.598 11.4754L290.333 11.4754C290.333 2.08316 296.953 0 306.317 0C316.045 0 321.739 1.85196 321.739 8.85763C321.739 19.7695 301.635 16.9109 299.858 22.5005Z" fill="#F5EB5D" />
                        <path d="M331.708 23.1919V28.5864H324.805V23.1919H331.708Z" fill="#F5EB5D" />
                        <path d="M333.402 14.455C333.402 5.11332 338.731 0.00537109 349.954 0.00537109C361.136 0.00537109 366.504 5.15907 366.504 14.455C366.504 23.7967 361.136 28.9889 349.954 28.9889C338.731 28.9889 333.402 23.8352 333.402 14.455ZM359.238 14.455C359.238 9.22183 356.341 6.08144 349.954 6.08144C343.568 6.08144 340.67 9.18089 340.67 14.455C340.67 19.7701 343.568 22.9105 349.954 22.9105C356.341 22.9105 359.238 19.7291 359.238 14.455Z" fill="#F5EB5D" />
                    </svg>
                </div>
                <div className="header-container-mobile">
                    <HeaderMedium28>{t("application_form.mobile.header")}</HeaderMedium28>
                    <Text16>{t("application_form.mobile.description")}</Text16>
                </div>
            </aside>
            <main>
                <StyledApplicationForm ref={formRef} onSubmit={e => applyStudent(e, userInfo, params.academyId)}>
                    <HeaderMedium28>{t("application_form.header")}</HeaderMedium28>
                    <div className="name-inputs-container">
                        <InputField
                            type="text"
                            required
                            name="given-name"
                            ref={nameInputRef}
                            onChange={() => setUserInfo({ ...userInfo, name: nameInputRef.current.value })}
                            autocomplete="family-name"
                            label={t("application_form.form.name")}
                            placeholder={t("application_form.form.name")}
                            value={nameInputRef.current?.value}
                            errors={errors.name}
                        />
                        <InputField
                            type="text"
                            required
                            name="family-name"
                            ref={surnameInputRef}
                            onChange={() => setUserInfo({ ...userInfo, surname: surnameInputRef.current.value })}
                            autocomplete="family-name"
                            label={t("application_form.form.surname")}
                            placeholder={t("application_form.form.surname")}
                            value={surnameInputRef.current?.value}
                            errors={errors.surname}
                        />
                    </div>
                    <div className="birth-date-container">
                        <Caption12>{t("application_form.form.birth_date.label")}*</Caption12>
                        <div className="birthDateInputsContainer">
                            <div className="birth-date-input-container day-input">
                                <SelectInput
                                    data={days}
                                    selectedOption={selectedDay}
                                    onChange={setSelectedDay}
                                />
                            </div>
                            <div className="birth-date-input-container month-input">
                                <SelectInput
                                    data={months}
                                    selectedOption={selectedMonth}
                                    onChange={setSelectedMonth}
                                />
                            </div>
                            <div className="birth-date-input-container year-input">
                                <SelectInput
                                    data={years}
                                    selectedOption={selectedYear}
                                    onChange={SetSelectedYear}
                                />
                            </div>
                        </div>
                        <Caption12 id="birthDateError" className="error">{useArrayLength(errors.birthDate) && errors.birthDate.map((error, index) => (<React.Fragment key={index}><span >{error}</span><br /></React.Fragment>))}</Caption12>
                    </div>
                    <div className="email-and-phone-container">

                        <InputField
                            type="email"
                            required
                            name="email"
                            ref={emailInputRef}
                            onChange={() => setUserInfo({ ...userInfo, email: emailInputRef.current.value })}
                            autocomplete="email"
                            label={t("application_form.form.email")}
                            placeholder={t("application_form.form.email")}
                            value={emailInputRef.current?.value}
                            className="email-input-container"
                            errors={errors.email}
                        />
                        <InputField
                            type="tel"
                            name="tel"
                            ref={phoneInputRef}
                            onChange={() => setUserInfo({ ...userInfo, phone: phoneInputRef.current.value })}
                            autocomplete="tel"
                            label={t("application_form.form.phone")}
                            placeholder={t("application_form.form.phone")}
                            value={phoneInputRef.current?.value}
                            className="phone-input-container"
                            errors={errors.phone}
                        />

                    </div>
                    <Caption12 color={props.theme.textError}>{useArrayLength(errors.no_term) && errors.no_term.map((error, index) => (<span key={index}><span>{error}</span><br /></span>))}</Caption12>
                    <Caption12 color={props.theme.textError}>{useArrayLength(errors.limit_exceeded) && errors.limit_exceeded.map((error, index) => (<span key={index}><span>{error}</span><br /></span>))}</Caption12>
                    <Checkbox text={t("application_form.form.you_agree")} checked={termsAgreement} error={errors.terms} onClick={() => setTermsAgreement(!termsAgreement)} link={{ text: t("general.terms_and_conditions"), onClick: () => setShowAllRulesPopup({ ...showAllRulesPopup, display: true, privacy: true }) }} />
                    <ButtonWide type="submit" text={t("general.continue")} />
                </StyledApplicationForm>
            </main>
        </ApplicationFormWrapper>
    )
}

export default withTheme(ApplicationForm);