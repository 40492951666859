import axios from 'axios';

let axiosSetup = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

axiosSetup.interceptors.request.use(
    (config) => {
        let guid = localStorage.getItem('guid')
        if (guid !== null) {
            config.params = { ...config.params, guid: guid }
        }
        return config;
    }
);

var createApiRoute = (route, data) => {
    let apiRoute = API.routes[route];
    if (typeof data !== typeof undefined) {
        for (let key in data) {
            apiRoute = apiRoute.replace(`{${key}}`, data[key]);
        }
    }

    return apiRoute;
}

var createRoute = (route, data) => {
    let apiRoute = API.appRoutes[route];
    if (typeof data !== typeof undefined) {
        for (let key in data) {
            apiRoute = apiRoute.replace(`{${key}}`, data[key]);
        }
    }

    return apiRoute;
}

//create url from parts, stripping the necessary slashes at the end of each part
var createRouteFromParts = (...parts) => {
    parts = parts.map(part => {
        return part && part.replace(/^\/+|\/+$/g, "");
    })

    let url = parts.join('/');

    return url;
}

// axiosSetup.defaults.withCredentials = true;

export const API = {
    createApiRoute: createApiRoute,
    createRoute: createRoute,
    createRouteFromParts: createRouteFromParts,
    axios: axiosSetup,
    routes: {
        academies: "academies/",
        user: "user/",
        questions: "questions/",
        assessment: "assessment/",
        result: "result/?guid={GUID}",
        term: "term/",
        legal: "legal/",
        resultsByAcademy: "results/academy/{ACADEMY_ID}",
        resultsByToken: "results/token/{TOKEN}"
    },
    appRoutes: {
        home: "/",
        apply: "/assessment/apply/{ACADEMY_ID}",
        applyWithReferral: "/assessment/apply/reffer/{REFERRAL_GUID}/{ACADEMY_ID}",
        confirm: "/assessment/confirm/{ACADEMY_ID}",
        assessment: "/assessment/",
        ongoing: "/assessment/ongoing",
        terms: "/terms_and_conditions",
        result: "/result/{GUID}",
        rules: "/rules/",
        awards: "/awards/{ACADEMY_ID}",
        awardsWithToken: "/awards/{ACADEMY_ID}/{TOKEN}",
        awardsPending: "/pending"
    },
    storage: process.env.REACT_APP_STORAGE_URL
};