import { useEffect, useState, useContext, useRef, useCallback } from 'react';

//npm imports
import styled, { withTheme } from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//component imports
import Wrapper from '../../Components/Wrapper/Wrapper';
import { Caption12, Header32, HeaderSmall24, Subheader18, Text12, Text14, Text16 } from '../../Components/Text/Text';
import BrainsterLogo from '../../Components/BrainsterLogo/BrainsterLogo';
import ButtonWide from '../../Components/ButtonWide/ButtonWide';
import SelectInput from '../../Components/SelectInput/SelectInput';

//other imports
import hero_background from '../Homepage/Blocks/HomepageHero/Static/hero-background.svg';
import results_table_background from './Static/results_table_background.svg';
import { voucher_card } from '../Homepage/Blocks/HomepageHero/Static/voucher_card';
import { API } from '../../Consts/Api';
import { GlobalContext } from '../../Context/GlobalContext';

//styled-components
const StyledAwardsMain = styled.main`
    width: 100%;
    min-height: 95vh;
    background-color: ${props => props.theme.gray};
    background-image: url(${hero_background});
    background-size: cover;
    background-position: center top;
    &>div{
        max-width: 1224px;
        &>.logo-and-voucher-container{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &>.voucher-card{
                margin-top: -70px;
            }
        }
        &>header{
            margin-top: 42px;
            ${Header32}{
                margin-bottom: 18px;
            }
        }
        &>div.check-rank-container{
            display: none;
        }
        &>section.awards-table-container{
            margin-bottom: -88px;
            margin-top: 60px;
            &>div.awards-tabs-container{
                display: flex;
                gap: 4px;
            }
            &>div.awards-academy-dropdown-container{
                display: none;
            }
            &>div.awards-results-container{
                width: 100%;
                padding: 64px 12px 24px 12px;
                background-image: url(${results_table_background});
                background-size: cover;
                background-position: left bottom;
                background-repeat: repeat-x;
                height: 64vh;
                position: relative;
                z-index: 9;
                ul{
                    width: 100%;
                    height: calc(64vh - 88px );
                    overflow-y: scroll;
                    padding: 0px 12px 0px 52px;
                    position: relative;
                    scrollbar-color: ${props => props.theme.darkGray} ${props => props.theme.black};
                    &::-webkit-scrollbar {
                        width: 7px;
                    }
                    &::-webkit-scrollbar-track, &::-webkit-scrollbar-track-piece {
                        background: ${props => props.theme.black};
                    }
                    li{
                        width: 100%;
                        padding: 12px 4px;
                        border-bottom: 1px solid ${props => props.theme.lightBlack};
                        &.active{
                            position: sticky;
                            bottom: 0;
                            top: 0;
                            background-color: ${props => props.theme.altYellow};
                            border-bottom: none;
                            p{
                                color: ${props => props.theme.black};
                            }
                        }
                        p{
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            color: ${props => props.theme.white};
                            .token{
                                flex-basis: 13%;
                                text-transform: uppercase;
                                user-select: all;
                            }
                            .add-padding{
                                padding-left: 8px;
                            }
                            .score{
                                flex-basis: 9%;
                            }
                            .time{
                                flex-basis: 25%;
                            }
                            .rank{
                                flex-basis: 11%;
                            }
                            .award{
                                flex-basis: 20%;
                                text-align: right;
                            }
                        }
                    }
                }
            }
            &>${Caption12}{
                margin: 4px 0;
            }
        }
    }

    @media (max-width: 1100px){
        &>div{
            &>.logo-and-voucher-container{
                &>.voucher-card{
                }
            }
            &>header{
                margin-top: 24px;
                padding-left: 12px;
                ${Header32}{
                    font-size: 24px;
                    
                }
                ${Subheader18}{
                    font-size: 16px;
                    font-weight: 400;
                }
            }
            
            &>div.check-rank-container{
                display: block;
                width: 100%;
                margin-top: 36px;
                
                ${Text14}{
                    padding-left: 12px;
                }
                &>.search-bar-container{
                    width: 100%;
                    display: flex;
                    gap: 8px;
                    margin-top: 16px;
                    input{
                        width: calc(90% - 8px);
                        background-color: ${props => props.theme.textGray};
                        border: none;
                        padding: 16px 20px;
                        font-size: 20px;
                        line-height: 23px;
                        text-transform: uppercase;
                    }
                    input:focus, input:active, input:visited{
                        outline: none;
                    }
                    &>button{
                        width: 10%;
                        background-color: ${props => props.theme.black};
                        border: none;
                        outline: none;
                        box-shadow: none;
                        &:focus, &:active, &:focus{
                            border: none;
                            outline: none;
                            box-shadow: none;
                        }
                    }
                }
                ${Caption12}{
                    margin-top: 8px;
                }
            }
            &>section.awards-table-container{
                padding-bottom: 60px;
                margin-top: 28px;
                &>div.awards-tabs-container{
                    display: none;
                }
                &>div.awards-academy-dropdown-container{
                    display: block;
                    position: relative;
                    z-index: 11;
                    margin-bottom: 8px;
                    ${Text12}{
                        padding-left: 12px;
                        margin-bottom: 8px;
                    }
                    .select-input__control{
                        background-color: ${props => props.theme.black};
                        border-radius: 0px;
                        border: none;
                        .select-input__menu{
                            border-radius: 0px;
                        }
                    }
                    .select-input__control:hover{
                        .select-input__single-value{
                            color: ${props => props.theme.altYellow};
                        }
                        svg{
                            path{
                                fill: ${props => props.theme.altYellow};
                            }
                        }
                    }
                }
                &>div.awards-results-container{
                    padding: 12px 12px 24px 12px;
                    background-image: none;
                    background-color: ${props => props.theme.black};
                    ul{
                        height: calc(64vh - 36px);
                        padding: 0px 12px 0px 12px;
                        position: static;
                        li{
                            p{
                                .token{
                                    flex-basis: 21%;
                                }
                                .award{
                                    flex-basis: 25%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 768px){
        &>div{
            &>section.awards-table-container{
                &>div.awards-results-container{
                    ul{
                        li{
                            p{
                                .rank{
                                    flex-basis: 3%;
                                }
                            }
                            ${Text12}{
                                font-size: 10px;
                            }
                            ${Text16}{
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 550px){
        &>div{
            &>div.check-rank-container{
                &>.search-bar-container{
                    input{
                        padding: 16px 14px;
                        width: calc(80% - 8px);
                    }
                    &>button{
                        width: 20%;
                    }
                }
            }
            &>section.awards-table-container{
                &>div.awards-academy-dropdown-container{
                    .select-input__value-container{
                        padding: 0;
                    }
                }
                &>div.awards-results-container{
                    ul{
                        padding: 0;
                        li{
                            padding: 12px 8px 12px 4px;
                            p{
                                .add-padding{
                                    padding-left: 2px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
@media (max-width: 425px){

    &>div{
        &>.logo-and-voucher-container{
            &>.voucher-card{
            }
        }
        &>header{
            ${Header32}{
                
            }
            ${Subheader18}{
            }
        }
        
        &>div.check-rank-container{
            
            ${Text14}{
            }
            &>.search-bar-container{
                input{
                }
                input:focus, input:active, input:visited{
                }
                &>button{
                    &:focus, &:active, &:focus{
                    }
                }
            }
            ${Caption12}{
            }
        }
        &>section.awards-table-container{
            &>div.awards-tabs-container{
            }
            &>div.awards-academy-dropdown-container{
                ${Text12}{
                }
                .select-input__value-container{
                    padding: 0;
                }
                .select-input__control{
                    .select-input__menu{
                    }
                }
            }
            &>div.awards-results-container{
                ul{
                    padding: 0;
                    li{
                        &.active{
                            p{
                            }
                        }
                        p{
                            .token{
                                flex-basis: 23%;
                            }
                            .score{
                                flex-basis: 19%;
                            }
                            .time {
                                flex-basis: 8%;
                            }
                            .award {
                                flex-basis: 20%;
                            }
                        }
                        ${Text12}{
                        }
                        ${Text16}{
                        }
                    }
                }
            }
        }
    }   
}
    
`

const AcademyTab = styled.button`
    flex-basis: 11.1%;
    padding: 16px 21px;
    background-color: ${props => props.highlight ? props.theme.black : props.theme.white};
    p{
        text-align: left;
        color: ${props => props.highlight ? props.theme.white : props.theme.darkGray};
    }
    border: none;
    outline: none;
    box-shadow: none;
    &:focus, &:active, &:visited {
        border: none;
        outline: none;
        box-shadow: none;
    }
    cursor: pointer;

`

const StyledAwardsSearchSection = styled.section`
    width: 100%;
    background-color: ${props => props.theme.veryLightGray};
    padding: 164px 0 100px 0;
    &>.check-rank-container{
        width: 80%;
        margin: 0 auto;
        max-width: 900px;
        &>.search-bar-container{
            width: 100%;
            display: flex;
            gap: 8px;
            margin-top: 16px;
            input{
                flex-grow: 1;
                background-color: ${props => props.theme.gray};
                border: none;
                padding: 16px 40px;
                font-size: 20px;
                line-height: 23px;
                text-transform: uppercase;
            }
            input:focus, input:active, input:visited{
                outline: none;
            }
            &>button{
                width: 25%!important;
            }
        }
        ${Caption12}{
            margin-top: 8px;
        }
    }
    @media (max-width: 1100px){
        display: none;
    }
    
`

//main component
const Awards = (props) => {
    const { t } = useTranslation();
    const { academies, term, checkDatesForDisplayingAwards } = useContext(GlobalContext);

    //store fetched results by academy in arrays inside the object state
    const [allResults, setAllResults] = useState({});

    const resetErrors = {
        message: ""
    }

    const [errors, setErrors] = useState(resetErrors);

    //store reference to the user token search input
    const searchBarRef = useRef();
    const mobileSearchBarRef = useRef();

    const [academiesForSelectInput, setAcademiesForSelectInput] = useState([]);

    //user token search bar value
    const [userToken, setUserToken] = useState("");

    //initial searchResultErrors object
    const resetSearchResultErrors = {
        message: ""
    }

    //store errors returned from fetching token search results
    const [searchResultErrors, setSearchResultErrors] = useState(resetSearchResultErrors);

    //keep user result info such as token and academy_id used to successfully navigate back to appointed academy and display result
    const [userSearchResult, setUserSearchResult] = useState(null);

    let params = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        if (term.results_date && !checkDatesForDisplayingAwards({ resultsDate: new Date(term.results_date) }, new Date())) {
            navigate(API.createRoute("awardsPending"));
        } else if (!params.academyId) {
            //if user lands on domain/awards (without academy id), redirect user to domain/awards/{first academy}
            if (academies.length > 0) {
                navigate(API.createRoute("awards", { ACADEMY_ID: academies[0].id }))
            }
        } else if (!allResults[params.academyId] && term.results_date && !checkDatesForDisplayingAwards({ resutlsDate: new Date(term.results_date) }, new Date())) {//when academyId changes in URL, check if results for that academy is not already stored in allResults hook
            //if not, fetch them from backend and store them in allResults hook for later use
            API.axios.get(API.createApiRoute("resultsByAcademy", { ACADEMY_ID: params.academyId }))
                .then(response => {
                    setAllResults({ ...allResults, [params.academyId]: response.data.data })
                })
                //DON'T FORGET TO HANDLE THIS ERROR
                .catch(error => {
                    if (error.response.status === 404) {
                        setErrors({ ...errors, message: t("errors.not_found") })
                    }
                    if (error.response.status === 500) {
                        setErrors({ ...errors, message: t("errors.error_occured") })
                    }
                })
        }

        if (params.academyId && academies.length > 0) {
            let dataForSelectInput = academies.map(academy => ({ value: academy.id, label: academy.name.replace("Академија за ", "") }))
            setAcademiesForSelectInput(dataForSelectInput);
        }

        //if token and academy id are in the URL, store them in userSearchResults 
        //for later sucessfull navigating and displaying search result in current session
        //store token to userToken thus setting the token search bar value to it
        if (params.token && params.academyId) {
            setUserSearchResult({ academy_id: params.academyId, token: params.token })
            setUserToken(params.token.toUpperCase());
        }

        //eslint-disable-next-line
    }, [params.academyId, params.token, academies, term.results_date])


    //if user lands on url that contains params.academyId that can't be found in database, redirect user to first academy tab
    useEffect(() => {
        if (academies.length > 0 && errors.message === t("errors.not_found")) {
            setErrors({ ...errors, message: "" })
            navigate(API.createRoute("awards", { ACADEMY_ID: academies[0].id }))
        }
        //eslint-disable-next-line
    }, [errors.message, academies, t, navigate])

    const searchUserResults = (token) => {
        if (token !== "") {
            //send token to backend
            API.axios.get(API.createApiRoute("resultsByToken", { TOKEN: token }))
                .then(response => {
                    //if response returns success
                    if (response.data.success) {
                        //navigate to domain/awards/ACADEMY_ID(from response)/token(from user input)
                        //thus securing remounting of application, re-rendering results for the appropriate academy and highlighting user's result
                        navigate(API.createRoute("awardsWithToken", { ACADEMY_ID: response.data.data.academy_id, TOKEN: token.toLowerCase() }))
                        //set response in userSearchResult and add token to it
                        setUserSearchResult({ ...response.data.data, token: token })
                        if (searchResultErrors.message) {
                            //remove errors
                            setSearchResultErrors(resetSearchResultErrors);
                        }
                    } else {
                        //if response returns success false, setErrors with appropriate message (token not found)
                        setSearchResultErrors({ ...searchResultErrors, message: t("awards.check_rank.errors.token_not_found") })
                    }
                })
                .catch(error => console.error(error.response))
        }
    }


    const submitOnEnter = useCallback((e) => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
            searchUserResults(userToken);
        }
        //eslint-disable-next-line
    }, [searchResultErrors, userToken])

    useEffect(() => {
        window.addEventListener('keydown', submitOnEnter);

        return () => {
            window.removeEventListener('keydown', submitOnEnter);
        };
    }, [submitOnEnter]);


    return (
        <>
            <StyledAwardsMain>
                <Wrapper>
                    <div className="logo-and-voucher-container">
                        <BrainsterLogo color={"white"} />
                        {voucher_card}
                    </div>
                    <header>
                        <Header32>{t("awards.header")}</Header32>
                        <Subheader18>{t("awards.subheader")}</Subheader18>
                    </header>
                    <div className="check-rank-container">
                        <Text14 fontWeight={700}>{t("awards.check_rank.header")}</Text14>
                        <div className="search-bar-container">
                            <input ref={mobileSearchBarRef} type="text" onChange={() => setUserToken(mobileSearchBarRef.current?.value.toUpperCase())} spellCheck={false} value={userToken} />
                            <button disabled={userToken === ""} onClick={() => searchUserResults(userToken)}>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.4531 9.88L17.5598 16L11.4531 22.12L13.3331 24L21.3331 16L13.3331 8L11.4531 9.88Z" fill={props.theme.altYellow} />
                                </svg>
                            </button>
                        </div>
                        <Caption12 color={props.theme.textError}>{searchResultErrors.message}</Caption12>
                    </div>
                    <section className="awards-table-container">
                        <Caption12 color={props.theme.textError}>{errors.message}</Caption12>
                        <div className="awards-tabs-container">
                            {academies.length > 0 && academies.map(academy => (
                                <AcademyTab
                                    highlight={parseFloat(academy.id) === parseFloat(params.academyId)}
                                    onClick={
                                        () => userSearchResult?.academy_id && parseFloat(userSearchResult.academy_id) === parseFloat(academy.id)
                                            ? navigate(API.createRoute("awardsWithToken", { ACADEMY_ID: userSearchResult.academy_id, TOKEN: userSearchResult.token }))
                                            : navigate(API.createRoute("awards", { ACADEMY_ID: academy.id }))
                                    }
                                    key={academy.id + academy.name}><Caption12>{academy.name.replace("Академија за ", "")}</Caption12></AcademyTab>
                            ))}
                        </div>

                        <div className="awards-academy-dropdown-container">
                            <Text12>{t("awards.dropdown.header")}</Text12>
                            <SelectInput
                                awards={true}
                                data={academiesForSelectInput}
                                selectedOption={academiesForSelectInput[academiesForSelectInput?.filter(academy => parseFloat(academy.value) === parseFloat(params.academyId))[0]?.value - 1]}
                                onChange={
                                    (newValue) => userSearchResult?.academy_id && parseFloat(userSearchResult.academy_id) === parseFloat(newValue.value)
                                        ? navigate(API.createRoute("awardsWithToken", { ACADEMY_ID: userSearchResult.academy_id, TOKEN: userSearchResult.token }))
                                        : navigate(API.createRoute("awards", { ACADEMY_ID: newValue.value }))
                                }
                            />
                        </div>

                        <div className="awards-results-container">
                            <ul>

                                <li>
                                    <Text12>
                                        <span className='token'>{t("awards.results_table.header.token")}</span>
                                        <span className='score'>{t("awards.results_table.header.score")}</span>
                                        <span className='time'>{t("awards.results_table.header.time_left")}</span>
                                        <span className='rank'>{t("awards.results_table.header.rank")}</span>
                                        <span className='award'>{t("awards.results_table.header.award")}</span>
                                    </Text12>
                                </li>
                                {allResults[params.academyId]?.length > 0 && allResults[params.academyId].map((applicant, index) => {
                                    //if the current applicant token is the search result token return li element with class of active
                                    //in other case return li element with onClick function to remove user search bar value
                                    if (params.token && applicant.token.toUpperCase() === params.token.toUpperCase()) {
                                        return (
                                            <li key={index} className='active'>
                                                <Text16>
                                                    <span className='token'>{applicant.token}</span>
                                                    <span className='score add-padding'>{applicant.score}</span>
                                                    <span className='time add-padding'>{applicant.time_left}</span>
                                                    <span className='rank add-padding'>{index + 1}</span>
                                                    <span className='award'>{applicant.award}</span>
                                                </Text16>
                                            </li>
                                        )
                                    } else {
                                        return (
                                            <li key={index} className='' onClick={() => setUserToken("")}>
                                                <Text16>
                                                    <span className='token'>{applicant.token}</span>
                                                    <span className='score add-padding'>{applicant.score}</span>
                                                    <span className='time add-padding'>{applicant.time_left}</span>
                                                    <span className='rank add-padding'>{index + 1}</span>
                                                    <span className='award'>{applicant.award}</span>
                                                </Text16>
                                            </li>
                                        )
                                    }
                                })
                                }

                            </ul>
                        </div>
                    </section>
                </Wrapper>
            </StyledAwardsMain>
            <StyledAwardsSearchSection>
                <div className="check-rank-container">
                    <HeaderSmall24>{t("awards.check_rank.header")}</HeaderSmall24>
                    <div className="search-bar-container">
                        <input ref={searchBarRef} type="text" onChange={() => setUserToken(searchBarRef.current?.value.toUpperCase())} spellCheck={false} value={userToken} />
                        <ButtonWide text={t("awards.check_rank.cta")} variation="large" disabled={userToken === ""} onClick={() => searchUserResults(userToken)} />
                    </div>
                    <Caption12 color={props.theme.textError}>{searchResultErrors.message}</Caption12>
                </div>
            </StyledAwardsSearchSection>
        </>
    )
}

export default withTheme(Awards);