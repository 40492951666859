import { useState, useEffect, useContext, useRef, useCallback } from 'react';

//npm imports
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components/macro';
import { useParams, useNavigate } from 'react-router';

//component imports
import { Header32, Text14, Text18 } from '../../Components/Text/Text';
import SelectInput from '../../Components/SelectInput/SelectInput';
import Checkbox from '../../Components/Checkbox/Checkbox';
import ButtonWide from '../../Components/ButtonWide/ButtonWide';
import AnimationSpinner from '../../Components/AnimationSpinner/AnimationSpinner';
import ButtonBasic from '../../Components/ButtonBasic/ButtonBasic';
import ArrowBack from '../../Components/ArrowBack/ArrowBack';

//other imports
import { GlobalContext } from '../../Context/GlobalContext';
import { API } from '../../Consts/Api';
import { exclamationMarkIcon } from './Static/exclamation-mark-icon';
import { useTranslation } from 'react-i18next';
import rules_logo from './Static/rules-logo.svg';
import rules_logo_mobile from './Static/rules-logo-mobie.svg';
import BrainsterLogo from '../../Components/BrainsterLogo/BrainsterLogo';
import popup_deco_line from './Static/popup_deco_line.svg'

const RulesAgreementWrapper = styled.section`
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: flex-end;
    &>.rules-agreement-container{
        width: 97%;
        background-color: ${props => props.theme.veryLightGray};
        display: flex;
        padding: 56px 8.4% 0 5.7%;
        aside{
            width: 37.8%;
            position: relative;
            img{
                margin-top: 30px;
            }
            .rules-spinner{
                position: absolute;
                top: 40%;
                left: 0px;
            }
        }
        main{
            width: 63.2%;
            .main-header{
                .main-header-mobile-controls{
                    display: none;
                }
                .important-banner{
                    background-color: ${props => props.theme.lightBlack};
                    display: flex;
                    border-radius: 4px;
                    padding: 16px 18px 18px 16px;
                    svg{
                        width: 60px;
                        height: 60px;
                    }
                }
            }
            .rules-checkboxes-container{
                margin-bottom: 72px;
                .rules-spinner-container {
                    display: none;
                }
                &>div{

                    margin-top: 50px;
                    &>p{
                        margin-bottom: 4px;
                    }
                    .rule-checkbox{
                        
                        margin: 20px 0;
                    }
                }
            }
            .academy-confirmation-container{
                margin-top: 44px;
                &>p{
                    margin-bottom: 8px;
                }
                &>div{
                    display: flex;
                    align-items: center;
                    &>p{
                        margin-right: 30px;
                    }
                    &>div{
                        width: 38%;
                        .input-label{
                            margin: 0;
                        }
                    }
                }
            }
            &>button{
                margin-bottom: 100px;
            }
        }
    }
    .start-popup-container{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,0.5);
        display: ${props => props.showPopup ? "flex" : "none"};
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .start-popup{
            max-width: 793px;
            padding: 64px 100px 56px 100px;
            background-color: ${props => props.theme.white};
            border-radius: 8px;
            position: relative;
            overflow: hidden;
            .exit-popup-button{
                position: absolute;
                top: 27px;
                right: 29px;
                background: transparent;
                border: none;
                padding: 0;
                &:hover{
                    cursor: pointer;
                }
            }
            .start-popup-content{
                display: flex;
                flex-direction: column;
                align-items: center;
                header{
                    display: flex;
                    align-items: center;
                    h4{
                        margin: 0 10px;
                    }
                }
                &>p{
                    text-align: center;
                    margin: 16px 0px 56px 0px;
                }
            }
            img{
                position: absolute;
                bottom: -2px;
                left: -20px;
            }
        }
    }
    @media (max-width: 1100px){
        &>.rules-agreement-container{
            main{
                .academy-confirmation-container{
                    &>div{
                        &>p{
                            width: 45%;
                        }
                        &>div{
                            width: 50%;
                        }
                    }
                }
            }
        }
        .start-popup-container{
            .start-popup{
                padding: 80px 32px 40px 32px;
            }
        }
    }
    @media (max-width: 825px){

        &>.rules-agreement-container{
            width: 100%;
            flex-direction: column;
            padding: 0;
            aside{
                display: none;
            }
            main{
                width: 100%;
                .main-header{
                    width: 100%;
                    background-color: ${props => props.theme.lightBlack};
                    padding-bottom: 40px;
                    padding-top: 32px;
                    &>div{
                        width: 82%;
                        margin: 0 auto;
                    }
                    .main-header-mobile-controls{
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 43px;
                    }
                    .important-banner{
                        padding: 0;
                        margin-bottom: 72px;
                        svg{
                            margin-right: 16px;
                        }
                    }
                }
                .rules-checkboxes-container>div ,.academy-confirmation-container {
                    width: 82%;
                    margin: 0 auto;
                }
                .rules-checkboxes-container{
                    width: 100%;
                    background-color: ${props => props.theme.veryLightGray};
                    margin-top: -40px;
                    border-top-right-radius: 40px;
                    padding: 24px 0;
                    .rules-spinner-container{
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 31px;
                        &>div{
                            width: 90px;
                            height: 90px;
                            svg{
                                width: 100%;
                                height: 100%;
                            }
                            .center-item{
                                width: 40%;
                                height: 40%;
                            }
                        }
                    }
                    &>div >p {
                        color: ${props => props.theme.darkGray};
                    }
                }
                .academy-confirmation-container{
                    margin-top: 24px;
                    background-color: ${props => props.theme.veryLightGray};
                    &>p{
                        color: ${props => props.theme.darkGray};
                    }
                    &>div{
                        flex-direction: column;
                        margin-bottom: 64px;
                        &>p, &>div{
                            width: 100%;
                        }
                        &>p{
                            margin: 0;
                            margin-bottom: 16px;
                            margin-top: 24px;
                        }
                    }
                }
                &>button{
                    width: 82%;
                    margin: 0 auto 68px auto;
                }
            }
        }
        .start-popup-container{
            .start-popup{
                max-width: 500px;
            }
        }
    }

    @media (max-width: 550px){

        &>.rules-agreement-container{
            main{
                .main-header{
                    &>div{
                        width: 92%;
                    }
                }
                .rules-checkboxes-container>div ,.academy-confirmation-container {
                    width: 92%;
                    margin: 0 auto;
                }

                .rules-checkboxes-container{
                    .rules-spinner-container{
                        &>div{
                            width: 60px;
                            height: 60px;
                        }
                    }
                }
                .academy-confirmation-container{
                    margin-top: 24px;
                }
                &>button{
                    width: 92%;
                }
            }
        }
        .start-popup-container{
            .start-popup{
                max-width: 343px;
                padding: 80px 16px 40px 16px;
                .start-popup-content{
                    header{
                        svg{
                            display: none;
                        }
                    }
                    &>p{
                        margin: 24px 0px 48px 0px;
                    }
                }
            }
        }
    }

    @media (max-width: 350px){
        .start-popup-container{
            .start-popup{
                max-width: 300px;
            }
        }
    }
`


const popupStarDeco = <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_646_88)">
        <path d="M12.8469 16.4108C12.8469 14.1658 14.6667 12.346 16.9117 12.346H24.4957C24.4991 12.2308 24.5009 12.1156 24.5009 11.9996C24.5009 11.8835 24.4991 11.7683 24.4957 11.6531H16.9117C14.6667 11.6531 12.8469 9.83334 12.8469 7.58829V0.00519687C12.7317 0.00173229 12.6165 0 12.5004 0C12.3844 0 12.2692 0.00173229 12.154 0.00519687V7.58916C12.154 9.83421 10.3342 11.654 8.08916 11.654H0.505197C0.501732 11.7692 0.5 11.8844 0.5 12.0004C0.5 12.1165 0.501732 12.2317 0.505197 12.3469H8.08916C10.3342 12.3469 12.154 14.1667 12.154 16.4117V23.9957C12.2692 23.9991 12.3844 24.0009 12.5004 24.0009C12.6165 24.0009 12.7317 23.9991 12.8469 23.9957V16.4108Z" fill="#F2E635" />
    </g>
    <defs>
        <clipPath id="clip0_646_88">
            <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
    </defs>
</svg>


const RulesAgreement = (props) => {
    const { t } = useTranslation();
    const { academies, setBodyUnscrollable, checkIfClickedOutside, expirationDate, setShowAllRulesPopup } = useContext(GlobalContext);

    const rules = [
        {
            id: 1,
            text: t("rules_agreement.rules.voucher_expiration"),
            agreed: true,
            error: false
        },
        {
            id: 2,
            text: t("rules_agreement.rules.disqualification"),
            agreed: false,
            error: false
        },
        {
            id: 3,
            text: t("rules_agreement.rules.brainster_only"),
            agreed: false,
            error: false
        },
        {
            id: 4,
            text: t("rules_agreement.rules.identification"),
            agreed: false,
            error: false
        },
        {
            id: 5,
            text: t("rules_agreement.rules.voucher_deviation"),
            agreed: false,
            error: false
        },
        {
            id: 6,
            text: t("rules_agreement.rules.privacy_policy"),
            agreed: false,
            error: false
        }
    ]
    const [rulesAgreement, setRulesAgreement] = useState(rules);
    const [areRulesAgreed, setAreRulesAgreed] = useState(false);

    const [academyOptions, setAcademyOptions] = useState([]);
    const [selectedAcademy, setSelectedAcademy] = useState({});


    const { startAssessment } = useContext(GlobalContext);

    const popupRef = useRef();
    const [showPopup, setShowPopup] = useState(false);

    let params = useParams();
    let navigate = useNavigate();


    useEffect(() => {
        return () => setBodyUnscrollable(false);
        //eslint-disable-next-line
    }, [])


    useEffect(() => {
        let academyOptionsInitial = [];
        if (academies.length > 0 && params.academyId) {
            academies.forEach(academy => {
                if (params.academyId) {
                    if (parseInt(academy.id) === parseInt(params.academyId)) {
                        setSelectedAcademy({ value: academy.id, label: academy.name })
                    }
                }
                academyOptionsInitial.push({ value: academy.id, label: academy.name })
            })
        }
        if (academyOptionsInitial) {
            setAcademyOptions(academyOptionsInitial);
        }
    }, [academies, params.academyId]);

    useEffect(() => {
        if (selectedAcademy.value && selectedAcademy.value !== params.academyId) {
            navigate(API.createRoute("confirm", { ACADEMY_ID: selectedAcademy.value }))
        }
        //eslint-disable-next-line
    }, [selectedAcademy.value])


    const agreeToARule = (ruleId) => {
        let rules = [...rulesAgreement];
        let agreedRules = [];
        rules.forEach(rule => {
            if (rule.id === ruleId) {
                rule.agreed = !rule.agreed;
                rule.error = false;
            }
            if (rule.agreed) {
                agreedRules.push(rule.id);
            } else {
                agreedRules.forEach((agreedRule, index) => {
                    if (rule.id === agreedRule) {
                        agreedRules.splice(index, 1)
                    }
                })
            }
        })
        if (agreedRules.length === rulesAgreement.length) {
            setAreRulesAgreed(true)
        } else if (areRulesAgreed) {
            setAreRulesAgreed(false)
        }
        setRulesAgreement(rules)
    }

    const validateRulesAgreement = () => {
        if (!areRulesAgreed) {
            let rules = [...rulesAgreement];
            rules.length > 0 && rules.forEach(rule => {
                if (!rule.agreed) {
                    rule.error = true;
                }
            })
            setRulesAgreement(rules)
        } else {
            setShowPopup(true);
        }
    }

    useEffect(() => {
        setBodyUnscrollable(showPopup)
        //eslint-disable-next-line
    }, [showPopup])


    const handleKeys = useCallback((e) => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
            startAssessment(selectedAcademy.value);
            navigate(API.createRoute("assessment"));
        }
        if (e.keyCode === 27) {
            setShowPopup(false);
        }
        //eslint-disable-next-line
    }, [setShowPopup, startAssessment, navigate])

    useEffect(() => {
        if (showPopup) {
            window.addEventListener('keydown', handleKeys);

        } else if (!showPopup) {
            window.removeEventListener("keydown", handleKeys)
        }

        return () => {
            window.removeEventListener('keydown', handleKeys);
        };
        //eslint-disable-next-line
    }, [showPopup])


    return (
        <RulesAgreementWrapper showPopup={showPopup}>
            <div className="rules-agreement-container">
                <aside>
                    <img src={rules_logo} alt="rules_header" />
                    <AnimationSpinner animationType="rules" className="rules-spinner" />
                </aside>
                <main>
                    <div className="main-header">
                        <div className='main-header-mobile-controls'>
                            <Link to={API.createRoute("apply", { ACADEMY_ID: params.academyId })} >
                                <ArrowBack color={props.theme.white} />
                            </Link>
                            <BrainsterLogo color={props.theme.white} />
                        </div>
                        <div className="important-banner">
                            {exclamationMarkIcon}
                            <div>
                                <Text18 lineHeight={26} color={props.theme.white}>{t("rules_agreement.important_banner.read_the_rules")}<br />{t("rules_agreement.important_banner.check_boxes")}</Text18>
                            </div>
                        </div>
                    </div>
                    <div className="academy-confirmation-container">
                        <Text14 fontWeight={700}>{t("rules_agreement.academy.header")}</Text14>
                        <div>
                            <Text18>{t("rules_agreement.academy.confirmation")}</Text18>
                            <SelectInput
                                data={academyOptions}
                                selectedOption={selectedAcademy}
                                onChange={setSelectedAcademy}
                            />
                        </div>
                    </div>
                    <div className="rules-checkboxes-container">
                        <div className="rules-spinner-container">
                            <img src={rules_logo_mobile} alt="rules_header" />
                            <AnimationSpinner animationType="rules" className="rules-spinner" />
                        </div>
                        <div>
                            <Text14 fontWeight={700}>{t("rules_agreement.rules.header")}</Text14>
                            {rulesAgreement.length > 0 && rulesAgreement.map((rule, index) =>
                            (
                                <Checkbox
                                    variation="large"
                                    error={rule.error}
                                    key={rule.id}
                                    onClick={() => agreeToARule(rule.id)}
                                    text={
                                        rule.text
                                            .replace("{ASSESSMENT_DURATION}", academies.length > 0 && academies.filter(academy => academy.id === parseFloat(params.academyId))[0]?.duration / 60)
                                            .replace("{EXPIRATION_DATE}", `${expirationDate.getDate() < 10 ? "0" : ""}${expirationDate.getDate()}.${expirationDate.getMonth() < 9 ? "0" : ""}${expirationDate.getMonth()}.${expirationDate.getFullYear()}`)
                                    }
                                    className="rule-checkbox"
                                    link={!rulesAgreement[index + 1] && { text: t("general.terms_and_conditions"), onClick: () => setShowAllRulesPopup({ display: true, privacy: true }) }}
                                    checked={rule.agreed}
                                />
                            ))}
                        </div>
                    </div>

                    <ButtonWide onClick={() => validateRulesAgreement()} text={t("general.continue")} />
                </main>
            </div>
            <div onClick={(e) => checkIfClickedOutside(e, popupRef, () => setShowPopup())} className="start-popup-container">
                <div ref={popupRef} className="start-popup">
                    <button className="exit-popup-button" onClick={() => setShowPopup(false)}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25.3337 8.54675L23.4537 6.66675L16.0003 14.1201L8.54699 6.66675L6.66699 8.54675L14.1203 16.0001L6.66699 23.4534L8.54699 25.3334L16.0003 17.8801L23.4537 25.3334L25.3337 23.4534L17.8803 16.0001L25.3337 8.54675Z" fill="#2D2E34" />
                        </svg>
                    </button>
                    <div className="start-popup-content">
                        <header>{popupStarDeco}<Header32>{t("rules_agreement.get_ready_popup.header")}</Header32>{popupStarDeco}</header>
                        <Text18>{t("rules_agreement.get_ready_popup.description").replace("{ASSESSMENT_DURATION}", academies.length > 0 && academies.filter(academy => academy.id === parseFloat(params.academyId))[0]?.duration / 60).replace("{QUESTION_NUMBER}", academies.length > 0 && academies.filter(academy => academy.id === parseFloat(params.academyId))[0]?.question_number)}</Text18>
                        <ButtonBasic variation="large" onClick={() => [startAssessment(selectedAcademy.value), navigate(API.createRoute("assessment"))]} text={t("rules_agreement.get_ready_popup.start_cta")} />
                    </div>
                    <img src={popup_deco_line} alt="decoration_line" />
                </div>
            </div>
        </RulesAgreementWrapper>
    )
}

export default withTheme(RulesAgreement);